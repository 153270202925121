import { useEffect, useState } from 'react'
import { Button, Form, Alert, Modal } from 'react-bootstrap'
import { Filter, Trash } from 'react-bootstrap-icons'
import { getOptions } from '../Classes/Base'
import LoadingBanner from './LoadingBanner'
import MessageCenter from './MessageCenter'
import SearchModal from './SearchModal'
import Queryset from '../Classes/Queryset'

export function FilterModal(props) {
    const { klass, onChange, activeFilter } = props
    const [show, setShow] = useState(false)
    const [options, setOptions] = useState()
    const [selectedFilters, setSelectedFilters] = useState({})

    const FILTER_FIELD_TYPES = ['model', 'bool']
    useEffect(() => {
        if (!klass?.filter_by) return;
        const retrieve = async () => {
            Object.entries(klass.filter_by).filter(([key, value]) => FILTER_FIELD_TYPES.indexOf(value.type) > -1 && key in activeFilter).map(([key, value]) => {
                const queryset = (new Queryset(value.klass))
                queryset.one(activeFilter[key]).then(obj => {
                    if (obj === undefined) {
                        filterSelected(key, queryset.object)
                    }
                })
                //
            })
            console.log("Filter loader", activeFilter, selectedFilters)
        }
        retrieve()
    }, [activeFilter, klass])

    useEffect(() => {
        getOptions(klass).then(options => setOptions(options)).catch(() => {
            MessageCenter.addMessage({
                title: "Nastala chyba!",
                text: "Selhalo načítání vlastností filtrů. Zkuste to znovu nebo kontaktujte podporu."
            })
        })
    }, [klass])

    if (!klass.filter_by) {
        return ""
    }

    function clearFilters() {
        setSelectedFilters({})
        onChange({})
        handleClose()
    }

    const handleClose = () => setShow(false);

    const handleShow = () => {
        setShow(true);
    }

    function onSave() {
        handleClose()
        onChange && onChange(selectedFilters)
    }

    function filterSelected(name, value) {
        if (value === undefined) {
            const f = {...selectedFilters}
            delete f[name]
            setSelectedFilters(f)
        }
        else {
            setSelectedFilters({
                ...selectedFilters,
                [name]: value
            })
        }
    }

    function renderField(field, name) {
        const field_options = options.actions.POST[name]
        switch(field.type) {
            case "model":
                return <div key={name} className="my-1 d-flex justify-content-between align-items-center">
                    <div><strong>{field_options.label}</strong></div>
                    <div>
                        {selectedFilters[name] !== undefined ?
                            <>
                                {selectedFilters[name] === null ?
                                <strong>chybí</strong> :
                                field.klass.prototype.label.call(selectedFilters[name])}
                                <Button variant="danger" size="sm" className="ml-1" onClick={() => filterSelected(name, undefined)}><Trash /></Button>
                            </> :
                            <SearchModal closeCallback={i => filterSelected(name, i)} emptySelector={`${field_options.label} - chybí`} klass={field.klass}/>}
                    </div>
                </div>
            case "bool":
                return <div key={name} className='d-flex'>
                    <Form.Check 
                            onChange={
                                e => filterSelected(name, e.target.checked ? field.value !== undefined ? field.value : true : undefined)
                            }
                            checked={selectedFilters[name] !== undefined}
                            className="ml-auto"
                            type='checkbox' label={field.label || (options.actions.POST[name] && options.actions.POST[name].label) || name}/>
                </div>
            default:
                MessageCenter.addMessage({
                    "title": "Nepodporovaný typ filtru",
                    "text": `Aplikace se pokusila filtrovat pomocí filtru typu "${field.type}". Není bohužel podporován, chybu prosím nahlašte podpoře`
                })
                break;
        }
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <Filter />
                {selectedFilters && Object.keys(selectedFilters).length > 0 && `(${Object.keys(selectedFilters).length})`}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtrování</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {options ? Object.entries(klass.filter_by).map(entry => {
                        const [name, filter] = entry
                        return renderField(filter, name)
                    }): <LoadingBanner />}
                {klass.filter_by_help && <Alert variant="info">
                    {klass.filter_by_help}
                </Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <div className='mr-auto'>
                            {Object.keys(selectedFilters).length > 0 && <Button variant="outline-danger" onClick={clearFilters}>
                                Odstranit filtry ({Object.keys(selectedFilters).length})
                            </Button>}
                        </div>
                        <div>
                            <Button variant="secondary" onClick={handleClose}>
                                Zavřít
                            </Button>
                            <Button className="ml-1" variant="primary" onClick={onSave}>
                                Uložit
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
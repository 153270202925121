import React, { useState, useEffect, useContext } from 'react';
import { Modal, Dropdown, Row, Col, Card, Button, Alert, Form, ButtonGroup, Container, Spinner } from 'react-bootstrap';
import { FileCode, Download, Search } from 'react-bootstrap-icons';
import { dateAddDays, dateRemoveTime, dateToInputValue } from '../../helpers';
import { ConfigContext } from '../../Context';
import Account from '../../Classes/Account';
import Invoice from '../../Classes/Invoice';
import Carrier from '../../Classes/Carrier';
import Customer from '../../Classes/Customer';
import MessageCenter from '../MessageCenter';



function PohodaExportModal(props) {
    const { order, onInvoiceCreated } = props
    const [show, setShow] = useState(false);
    const [saving, setSaving] = useState(false);
    const [dueDate, setDueDate] = useState(dateAddDays(dateRemoveTime(new Date()), order.customer?.maturity || 20));
    const [customerOrder, setCustomerOrder] = useState(order.customer_order || "")
    const config = useContext(ConfigContext)

    useEffect(() => {
        setCustomerOrder(order.customer_order)
    }, [order, show])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const invoiceConditions = order.customer && (order.carrier || (!order.invoice && order.carrier_missing_invoice));
    const isAccounting = Account.current().hasPerm("accounting");

    async function createInvoice() {
        if (order.margin <= 0 && !window.confirm("Chcete vytvořit fakturu k objednávce bez marže?")) return;
        setSaving(true)
        const invoice = new Invoice({
            id: 0,
            customer_order: customerOrder,
            customer: order.customer.id,
            orders: [order],
            due_date: dateToInputValue(dueDate)
        })
        try {
            const response = await invoice.save();
            if (invoice.__status !== 201) throw Error(`Unexpected HTTP status ${invoice.__status}: ${response}`)
            onInvoiceCreated && onInvoiceCreated(invoice)
            handleClose()
        }
        catch (e) {
            MessageCenter.addMessage({
                title: "Nepodařilo se uložit fakturu!",
                text: "Zkuste to znovu, případně kontaktujte podporu"
            })
            console.error(e)
        }
        finally {
            setSaving(false)
        }
    }

    async function pohodaDownload() {
        await (new Invoice(order.invoice).to_pohoda())
        window.location.reload()
    }

    return <>
        <Dropdown.Item onClick={() => handleShow()}><FileCode /> Pohoda XML</Dropdown.Item>

        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Pohoda XML export</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-5">
                <Row>
                    <Col>
                        <Card className='h-100' bg={order.carrier ? "" : "danger"}>
                            <Card.Body bg="danger">
                                <Card.Title>Přijatá objednávka</Card.Title>
                                <Card.Text as="div">
                                    {order.carrier ?
                                        <>Objednávka vystavená dopravcem <br /><em>{Carrier.icon_as_component()} {new Carrier(order.carrier).label()}</em></> :
                                        "Nelze stáhnout objednávku od dopravce, protože dopravce není přiřazený."}
                                </Card.Text>
                            </Card.Body>
                            {order.carrier && <Button onClick={() => { order.pohodaXMLDownload("received") }} variant="primary"><Download /> Stáhnout</Button>}
                        </Card>
                    </Col>
                    <Col>
                        <Card className='h-100' bg={order.customer ? "" : "danger"}>
                            <Card.Body bg="danger">
                                <Card.Title>Přijatá objednávka</Card.Title>
                                <Card.Text as="div">
                                    {order.customer ?
                                        <>Objednávka vystavená pro zákazníka <br />{Customer.icon_as_component()} <em>{new Customer(order.customer).label()}</em></> :
                                        "Nelze stáhnout objednávku pro zákazníka, protože zákazník není přiřazený"}
                                </Card.Text>
                            </Card.Body>
                            {order.customer && <Button onClick={() => { order.pohodaXMLDownload("issued") }} variant="primary"><Download /> Stáhnout</Button>}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {config.accounting ? <Card className='h-100 mt-3' bg={invoiceConditions || !isAccounting ? "" : "danger"}>
                            {isAccounting ? <Card.Body>
                                <Card.Title>Vystavit fakturu</Card.Title>
                                <Card.Text as="div">
                                    {invoiceConditions && order.margin <= 0 && <Alert variant='danger'>
                                        <strong>Bez marže!</strong> Objednávka je bez marže. Zkontrolujte prosím uvedené ceny.
                                    </Alert>}
                                    {order.invoice ?
                                        "Objednávka už má vystavenou fakturu. Stahování faktur probíhá na stránce faktury." :
                                        invoiceConditions ? <>
                                            <Alert variant='primary' className='p-1 text-center'>
                                                <small>Pro fakturaci více objednávek použijte funkci "Účetnictví ⇒ Objednávky"</small>
                                            </Alert>
                                            <Form.Group controlId='due_date'>
                                                <Form.Label>Datum splatnosti</Form.Label>
                                                <Form.Control type="date" value={dateToInputValue(dueDate)} onChange={e => setDueDate(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group controlId='due_date'>
                                                <Form.Label>Číslo objednávky u zákazníka</Form.Label>
                                                <Form.Control value={customerOrder} onChange={e => setCustomerOrder(e.target.value)} placeholder="použít logisticiq id" />
                                                <Form.Text>
                                                    Označení objednávky u zákazníka. Použije se na vygenerovaných dokumentech.
                                                </Form.Text>
                                            </Form.Group>
                                        </> : <div>
                                            Není možné vystavit fakturu, nejsou splněné podmínky!
                                            <ul>
                                                {!order.customer && <li>Není přiřazen zákazník</li>}
                                                {!order.carrier ? <li>Není přiřazen dopravce </li> : (!order.carrier_order || !order.carrier_missing_invoice) && <li>Je přiřazen dopravce, ale není vyřešena jeho objednávka. Pokud chybí označte odpovídající pole</li>}
                                            </ul>
                                        </div>
                                    }
                                </Card.Text>
                            </Card.Body> : <Card.Body><Alert variant='secondary'>
                                Uživatelé s odpovídajícím oprávněním mohou také vystavit fakturu přímo v systému LogisticIQ. Možnost může zapnout správce systému.
                            </Alert></Card.Body>}
                            {order.invoice ?
                                <ButtonGroup>
                                    <Button onClick={pohodaDownload}><Download /> Stáhnout</Button>
                                    <Button variant="outline-primary" href={`/invoice/${order.invoice.id}`}><Search /> Zobrazit</Button>
                                </ButtonGroup> : invoiceConditions && isAccounting && <Button onClick={createInvoice}>Vytvořit</Button>}
                        </Card> : <Alert variant='secondary' className='mt-3'>
                            <em>Systém logistic podporuje fakturaci. Integrace s pohodou umožňuje sledovat stav a prodlevy objednávek zpracovaných v pohodě. </em>
                        </Alert>}
                    </Col>
                </Row>
                {saving && <div className="fixed-bottom">
                    <Container>
                        <Row>
                            <Col sm={12} md={8} lg={4} className="ml-auto bg-light d-flex p-2 mb-1 border rounded align-items-center justify-content-center">
                                <Spinner animation="border" variant="info" /> <h3 className="ml-3 mb-0">Stahování</h3>
                            </Col>
                        </Row>
                    </Container>
                </div>}
            </Modal.Body>
        </Modal>
    </>
}

export default PohodaExportModal
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Order from '../Classes/Order'
import MessageCenter from '../Components/MessageCenter'
import React, { useEffect } from 'react'
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Account from "../Classes/Account";
import { ExclamationSquare, Search } from "react-bootstrap-icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const WARNING_THRESHOLD = 5
const ERROR_THRESHOLD = 10

function OrdersWithoutTransportation() {
    const [orders, setOrders] = React.useState([])

    useEffect(() => {
        Order.withoutTransportation().then((data) => {
            if (data === null) {
                throw new Error('No data!')
            }
            setOrders(data)
        }).catch((error) => {
            MessageCenter.addMessage({
                title: 'Chyba při načítání objednávek bez dopravy!',
                text: 'Bohužel se nepovedlo načíst objednávky bez dopravy. Zkuste to prosím znovu, nebo kontaktujte podporu.',
            })
        })
    }, [])

    const count = orders?.count || 0

    if (count === 0) {
        return <></>
    }

    const color = count >= ERROR_THRESHOLD ? 'danger' : count >= WARNING_THRESHOLD ? 'warning' : 'info'

    return <OverlayTrigger
        placement="bottom"
        key="overlay"
        overlay={<Tooltip id="tooltip-orders">
            {orders?.orders?.map(order => <div key={order.id}>{order.id} - {order.code}</div>)}
        </Tooltip>}
    >
        <span className={`text-${color}`}>
            {count > ERROR_THRESHOLD && <ExclamationSquare className="mr-1" />}
            <strong>Upozornění:</strong> Počet objednávek bez dopravy - <strong>{count}</strong>.
        </span>
    </OverlayTrigger>
}

class OrderView extends ModelView {
    constructor(props) {
        super(Order, props)
        this.jumbotronTitle = "Objednávky"
        this.jumbotronSubtitle = <>
            <em>Zvýrazněné objednávky  nemají vozidlo, ani dopravce.</em><br />
            <OrdersWithoutTransportation />
        </>
        this.modalNewPlaceholder = "Nová objednávka"
        this.modalNewLabel = "Nová objednávka"
        this.modalNewTitle = "Nová objednávka"
        this.show_controls = false
        this.new_button = <Button variant="success" onClick={() => { this.new() }}>
            Nová objednávka
        </Button>
        this.extra_buttons = [<Button key="orderimporter" onClick={() => { this.props.history.push("/order-filter/") }}>Filtrování objednávek</Button>]
        this.filters = [
            {
                label: "Stav",
                type: "select",
                name: "state",
                default: "NEW",
                getValues: () => {
                    return this.state.options ?
                        this.state.options.actions.POST.state.choices : []
                }
            }
        ]
        if (Account.current().level > 0 || Account.current().user_permissions.filter(i => i.codename === 'all_orders').length) {
            this.filters.push({
                label: "Jenom mé objednávky",
                type: "checkbox",
                default: window.location.host.includes('wasso') || window.location.host.includes('localhost') ? Account.currentUser().id : null,
                name: "user",
                value: Account.currentUser().id
            })
        }
    }

    highlight(item) {
        if (!item.carrier && !item.vehicle)
            return "Nemá dopravce ani vozidlo"
    }


    async new() {
        let object = new Order({ "id": 0 })
        object = await object.save()
        if (object.id !== undefined && object.id > 0) {
            this.itemDetail(object)
        }
        else {
            MessageCenter.addMessage({
                title: 'Chyba při ukládání!',
                text: 'Bohužel se nepovedlo uložit objednávku. Zkuste to prosím znovu, nebo kontaktujte podporu',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(object)
            })
        }
    }
}

export default withRouter(OrderView);

import React from 'react'
import { Button, ButtonGroup, ButtonToolbar, Dropdown } from 'react-bootstrap';
import { ExclamationTriangleFill, Link45deg, ListOl, ListUl, TypeStrikethrough } from 'react-bootstrap-icons'
import { TypeBold, TypeItalic, TypeUnderline } from 'react-bootstrap-icons';
import { Editor, EditorState, Modifier, RichUtils, CompositeDecorator } from 'draft-js';
import { OrderedSet } from 'immutable';

/**
 * RichTextEditor component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The initial value of the editor.
 * @param {Function} props.onChange - The callback function to handle changes in the editor.
 * @return {JSX.Element} The rendered RichTextEditor component.
*/
const RichTextEditor = ({ setEditorState, editorState, topBarExtension, features }) => {
    const Link = ({ entityKey, contentState, children }) => {
        const { url } = contentState.getEntity(entityKey).getData();
        return <a href={url}>🔗 {children}</a>;
    };

    const findLinkEntities = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(character => {
            const entityKey = character.getEntity();

            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === "LINK"
            );
        }, callback);
    };

    const createLinkDecorator = () =>
        new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link
            }
        ]);
    const decorator = createLinkDecorator();
    const formats = [{ type: "normal", dropdown_title: "normální", title: <>normální</> },
    { type: "code-block", dropdown_title: "kód", title: <>kód</> },
    { type: "header-one", dropdown_title: "nadpis 1", title: <><h1>nadpis 1</h1></> },
    { type: "header-two", dropdown_title: "nadpis 2", title: <><h2>nadpis 2</h2></> },
    { type: "header-three", dropdown_title: "nadpis 3", title: <><h3>nadpis 3</h3></> },
    { type: "header-four", dropdown_title: "nadpis 4", title: <><h4>nadpis 4</h4></> },
    { type: "header-five", dropdown_title: "nadpis 5", title: <><h5>nadpis 5</h5></> },
    { type: "header-six", dropdown_title: "nadpis 6", title: <><h6>nadpis 6</h6></> },
    { type: "ordered-list-item", dropdown_title: "číslovaný seznam", title: <><ol><li>číslovaný seznam</li></ol></> },
    { type: "unordered-list-item", dropdown_title: "nečíslovaný seznam", title: <><ol><li>nečíslovaný seznam</li></ol></> },
    ];
    const font_styles = [
        { type: "BOLD", title: "tučně", sign: <TypeBold /> },
        { type: "ITALIC", title: "kurziva", sign: <TypeItalic /> },
        { type: "UNDERLINE", title: "podtržení", sign: <TypeUnderline /> },
        { type: "STRIKETHROUGH", title: "přešktnutí", sign: <TypeStrikethrough /> },
        { type: "LINK", title: "vložit odkaz", sign: <Link45deg /> },
    ]
    let size = features?.small_buttons === true ? "sm" : ""
    const [innerEditorState, setInnerEditorState] = React.useState(
        editorState || EditorState.createEmpty(decorator)
    );
    const [format, setFormat] = React.useState(formats[0]);
    const [update, setUpdate] = React.useState(false);
    const [styles, setStyles] = React.useState(OrderedSet());
    const [error, setError] = React.useState([]);
    const [seed, setSeed] = React.useState(1);


    React.useEffect(() => {
        if (features?.load_changes !== false && update) {
            setInnerEditorState(editorState || EditorState.createEmpty(decorator))
        }
        setUpdate(true)
    }, [editorState]);

    /**
     * Handles the click event on a style button.
     *
     * @param {Event} ev - The click event.
     * @return {void}
     */
    const onStyleButtonClick = (ev) => {
        try {
            const type = ev.target.dataset.type;
            if (!font_styles.map(item => item.type).includes(type)) {
                throw new Error(`Unknown style: "${type}"`)
            }
            onEditorStateChange(RichUtils.toggleInlineStyle(innerEditorState, type));
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    };
    /**
     * Handles the click event on a color button.
     *
     * @param {Event} ev - The click event.
     * @return {void}
     */
    const onColorButtonClick = (ev) => {
        try {
            if (!/^#[0-9A-F]{6}$/i.test(ev.target.value)) {
                throw new Error(`"${ev.target.value}" is not a color`)
            }
            const colorKey = 'color_' + ev.target.value.substring(1)
            const currentStyles = innerEditorState.getCurrentInlineStyle().toJS()
            let nextEditorState = [...currentStyles, colorKey].reduce(
                (state, style) =>
                    style.startsWith("color_")
                        ? RichUtils.toggleInlineStyle(state, style)
                        : state,
                innerEditorState
            )
            onEditorStateChange(nextEditorState);
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    };

    const onDropdownButtonClick = (ev) => {
        try {
            const option = Number(ev)
            const type = formats[option].type;
            formatText(type)
            setFormat(formats[option])
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    }


    /**
     * Handles the click event on a list button.
     *
     * @param {Event} ev - The click event.
     * @return {void}
     */
    const onListButtonClick = (ev) => {
        try {
            const type = ev.target.dataset.type
            formatText(type)
            setFormat(formats.find(item => item.type === type) ? formats.find(item => item.type === type) : formats[0])
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    }


    /**
     * Updates the editor state by toggling the specified block type.
     *
     * @param {string} type - The type of block to toggle.
     * @return {void} This function does not return anything.
     */
    const formatText = (type) => {
        try {
            onEditorStateChange(RichUtils.toggleBlockType(innerEditorState, type))
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    }

    /**
     * Resets the component.
     *
     * @return {void} This function does not return anything.
     */
    const reset = () => {
        setError([])
        setSeed(Math.random());
    }

    /**
     * Updates the editor state and performs various actions based on the new state.
     *
     * @param {EditorState} newState - The new state of the editor.
     * @return {void} This function does not return anything.
     */
    const onEditorStateChange = (newState) => {
        try {
            setInnerEditorState(EditorState.set(newState, { decorator: decorator }));
            setUpdate(false)
            setStyles(newState.getCurrentInlineStyle())
            const type = newState.getCurrentContent().getBlockForKey(newState.getSelection().getAnchorKey()).getType();
            setFormat(formats.find(item => item.type === type) ? formats.find(item => item.type === type) : formats[0])
            setEditorState(newState)
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    };


    /**
     * Generates a custom style object based on the provided style array.
     *
     * @param {List<string>} style - The style array containing style names.
     * @return {Object} The custom style object with color properties.
     */
    const customStyleFn = (style) => {
        try {
            const styles = style.toJS() // ['BOLD', 'color_FF600A', ...]
            if (!Array.isArray(styles)) {
                throw new Error(`when getting current styles & color, expected array, but got  ${typeof styles}`)
            }
            return styles.reduce(
                (styleMap, styleName) =>
                    styleName?.startsWith("color_")
                        ? { color: `#${styleName.split("color_")[1]}` }
                        : styleMap || {}, // the `|| {}` is just for more security that  the style map can't be empty
                {}
            )
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    }

    /**
     * Handles a key command in the editor.
     *
     * @param {string} command - The key command to handle.
     * @param {EditorState} editorState - The current state of the editor.
     * @return {string} - Returns 'handled' if the command is successfully handled, otherwise 'not-handled'.
     */
    const handleKeyCommand = (command, editorState) => {
        try {
            const newState = RichUtils.handleKeyCommand(editorState, command);
            if (newState) {
                onEditorStateChange(newState);
                return 'handled';
            }
            return 'not-handled';
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    }

    /**
     * Handles the click event on the link button.
     *
     * @param {Event} ev - The click event.
     * @return {void}
     */
    const onLinkButtonClick = (ev) => {
        try {
            let linkUrl = window.prompt("Vložte odkaz");
            if (linkUrl) {
                const selection = innerEditorState.getSelection();
                const text = innerEditorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset());
                const currentContent = innerEditorState.getCurrentContent();
                const contentStateWithEntity = currentContent.createEntity(
                    "LINK",
                    "MUTABLE",
                    { url: `${linkUrl.search("/^https?:\\/\\/") === 0 ? "" : "https://"}${linkUrl.search("/^https?:\\/\\/www\\.") === 0 ? "" : "www."}${linkUrl}` }
                );
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const textWithEntity = Modifier.replaceText(
                    contentStateWithEntity,
                    selection,
                    text,
                    null,
                    entityKey
                );
                let newState = EditorState.push(innerEditorState, textWithEntity, 'insert-characters');

                onEditorStateChange(newState);
            }
        } catch (err) {
            setError([...error, err])
            console.error(error)
        }
    }

    return (
        <div key={seed} className="Editor editor-wrapper border rounded">
            {error.length === 0 ?
                (<>
                    <div className="Editor-tools">
                        <ButtonToolbar aria-label="Toolbar with button groups" className="border-bottom p-2">
                            <ButtonGroup>
                                {features?.styles !== false && <>
                                    {font_styles.slice(0, 4).map((style, index) =>
                                        <Button key={index} variant={(styles.contains(style.type) ? "" : "outline-") + "primary"}
                                            title={style.title} size={size} onClick={onStyleButtonClick} data-type={style.type}>
                                            {React.cloneElement(style.sign, {
                                                onClick: onStyleButtonClick,
                                                'data-type': style.type
                                            })}
                                        </Button>
                                    )}
                                </>
                                }
                                {features?.formats !== false && <Dropdown
                                    onSelect={onDropdownButtonClick}
                                    autoClose="inside"
                                >
                                    <Dropdown.Toggle
                                        variant="outline-primary"
                                        size={size}
                                    >
                                        {format.dropdown_title}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        {formats.slice(0, 6 + (features?.headers_all === true ? 2 : 0)).map(({ title }, index) => (
                                            <Dropdown.Item key={index} eventKey={index}>{title}</Dropdown.Item>
                                        ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>}
                                {(features?.link !== false) && <Button variant={(styles.contains(font_styles[4].type) ? "" : "outline-") + "primary"}
                                    size={size} title={font_styles[4].title} onClick={onLinkButtonClick} data-type={font_styles[4].type}>
                                    {React.cloneElement(font_styles[4].sign, {
                                        onClick: onStyleButtonClick,
                                        'data-type': font_styles[4].type
                                    })}
                                </Button>
                                }
                                {features?.lists !== false &&
                                    <>
                                        <Button variant={((format.type === "ordered-list-item") ? "" : "outline-") + "primary"} size={size} onClick={onListButtonClick} data-type="ordered-list-item">
                                            <strong><ListOl data-type="ordered-list-item" /></strong>
                                        </Button>
                                        <Button variant={((format.type === "unordered-list-item") ? "" : "outline-") + "primary"} size={size} onClick={onListButtonClick} data-type="unordered-list-item">
                                            <ListUl data-type="unordered-list-item" />
                                        </Button>
                                    </>
                                }
                                {features?.color !== false &&
                                    <Button
                                        as="input"
                                        type="color"
                                        size={size}
                                        value={styles.some(e => e.startsWith("color_")) ? `#${styles.find(e => e.startsWith("color_")).substring(6)}` : "#000000"}
                                        variant={"outline-primary"}
                                        onChange={onColorButtonClick}
                                        title="Vybrat barvu" />
                                }
                            </ButtonGroup>
                            {topBarExtension && <ButtonGroup className="ml-auto" aria-label="Second group">
                                {topBarExtension}
                            </ButtonGroup>}
                        </ButtonToolbar>
                    </div>
                    <Editor
                        editorState={innerEditorState}
                        onChange={onEditorStateChange}
                        customStyleFn={customStyleFn}
                        handleKeyCommand={handleKeyCommand}
                    />
                </>
                ) :
                (
                    <>
                        <h1><ExclamationTriangleFill /> Chyba</h1>
                        <div>Prosím, neprodleně se obraťte na podporu</div>
                        <ul>{error.map((e, i) => <li key={i}>{e.name}: {e.message}</li>)}</ul>
                        <Button onClick={reset}>Restartovat komponent</Button>
                    </>
                )


            }
        </div >
    )
};

export default RichTextEditor;
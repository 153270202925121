import React from 'react'
import { Container, Spinner, ButtonToolbar, ButtonGroup, Form, Button, Jumbotron, Table, Pagination, Row, Col, Dropdown, DropdownButton} from 'react-bootstrap';
import MessageCenter from '../Components/MessageCenter'
import { getOptions } from "../Classes/Base"
import Queryset from '../Classes/Queryset'
import ModalNew from '../Components/ModalNew';
import { Trash, Search, ArrowsFullscreen, FullscreenExit, SortUp, SortDown, ArrowLeftShort, ArrowRightShort, ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons';
import Account from '../Classes/Account';
import {HelpComponent} from '../Components/HelpComponent'
import LoadingBanner from '../Components/LoadingBanner';
import { FilterModal } from '../Components/FilterModal';

const SEARCH_COOLDOWN = 400
const PAGES_VISIBLE = 10

class ModelView extends React.Component {
    // The model links to no detailview
    noDetailView = false
    //attribute for creating new entry
    newAttribute = "name"
    // left sidebar
    left_sidebar = null
    // extra actions will be render in the button toolbar
    extra_actions = []
    // extra controls are rendered next to the delete  button
    extra_controls = []

    constructor(klass,props) {
        super(props)
        this.redirect = props.redirect || true
        this.klass = klass
        this.state = {
            queryset : new Queryset(klass),
            search: "",
            filter: {page: 1},
            options: undefined,
            limit: undefined,
            help: undefined,
            wide: !!localStorage.getItem(window.location.href + '-wide')
        }
        this.modal_ref = React.createRef()
        this.show_controls = true
        this.search_running = false
        this.enable_delete = true
        this.extra_buttons = props.extra_buttons || []
    }

    defaultColumns() {
        return [
            {
                name: "id",
                label: "#",
            },
            {
                name: "name",
                label: "Jméno"
            }
        ]
    }

    async updateFilterBulk(filter) {
        await this.setState({filter})
    }

    makeHistoryEntry() {
        // don't store history with page == 1 (default value)
        if (this.props.history && this.props.location) {
            let path = this.props.location.pathname + (this.props.location.search ? `?${this.props.location.search}` : "")
            this.props.history.push(path, { filter: this.state.filter })
            console.debug("Storing the history filter: ", this.state.filter)
        }
        else {
            console.log("History can't be stored on this view. Not supported")
        }
    }

    async loadData(update) {
        this.loadCount()
        delete this.state.filter.search
        if (this.state.search !== "") {
            
            this.state.filter.search = this.state.search
        }
        await this.state.queryset.filter({
                ...this.state.filter,
                limit: this.state.filter.limit || 15
        })
        this.state.limit || this.setState({limit: this.state.queryset.count})
        // Set a history entry
        if (update !== false) {
            this.forceUpdate()
        }
    }

    async deleteEntry(value) {
        if (!window.confirm("Opravdu chcete tento záznam smazat?")) {
            return;
        }
        if (await value.delete()) {
            this.state.queryset.remove(value)
            this.forceUpdate()
        }
    }

    async setDefaultFilters() {
        // Use location state if provided by history!
        if (this?.props?.location?.state?.filter) {
            console.debug("Previous history filter loaded", this?.props?.location?.state?.filter)
            await this.setState({
                filter: this.props.location.state.filter,
                search: this.props.location.state.filter.search || ""
            })
        }
        else {
            const filter_with = {}
        
            this.filters && this.filters.forEach(filter => {
                if (filter.default !== null )
                    filter_with[filter.name] = filter.default
            })
            filter_with.page = 1
            filter_with.limit = 15
            if(arguments.length > 0 && arguments[0].order_by !== undefined)
                filter_with.order_by = arguments[0].order_by
            await this.updateFilterBulk(filter_with)
        }
    }

    async loadCount() {
        const filter = {...this.state.filter}
        delete filter.page
        delete filter.limit
        delete filter.search
        if (this.state.search !== "") {
            filter.search = this.state.search
        }
        const count = await this.klass.serverCount(filter)
        this.setState({"server_count" : count})
    }

    async componentDidMount() {
        await this.setDefaultFilters()
        const options = await getOptions(this.klass)
        this.setState({options})
        await this.loadData(false)
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const oldHistoryKey = prevProps?.history
        const currentHistoryKey = this.props?.history
        //console.log("History keys", oldHistoryKey, currentHistoryKey)
    }

    async new() {
        const name = this.modal_ref.current.state.name
        let object = new this.klass({"id": 0, [this.newAttribute]:name})
        const res = await object.save()
        if (res) {
            if (object.id === 0) {
                MessageCenter.addMessage({
                    title: 'Chyba při ukládání!',
                    text:'Bohužel se nepovedlo uložit nový objekt! Zkuste to prosím znovu, nebo kontaktujte podporu',
                    detail: 'Data použitá k uložení: \n' + JSON.stringify(object) + "\n" + JSON.stringify(object.__messages)
                })
                return;
            }
            this.state.queryset.push(object);
            this.forceUpdate()
            this.modal_ref.current.handleClose()
            this.redirect && this.itemDetail(object)
        } else {
            MessageCenter.addMessage({
                title: 'Chyba při ukládání!',
                text:'Bohužel se nepovedlo uložit nový objekt! Zkuste to prosím znovu, nebo kontaktujte podporu',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(object)
            })
        }
        return object
    }

    async new_ico(data) {
        data = data["data"]
       data.id = 0
        let object = new this.klass(data)
        const res = await object.save()
        if (res) {
            if (object.id === 0) {
                MessageCenter.addMessage({
                    title: 'Chyba při ukládání!',
                    text:'Bohužel se nepovedlo uložit nový objekt! Zkuste to prosím znovu, nebo kontaktujte podporu',
                    detail: 'Data použitá k uložení: \n' + JSON.stringify(object) + "\n" + JSON.stringify(object.__messages)
                })
                return;
            }
            this.state.queryset.push(object);
            this.forceUpdate()
            this.modal_ref.current.handleClose()
            this.redirect && this.itemDetail(object)
        } else {
            MessageCenter.addMessage({
                title: 'Chyba při ukládání!',
                text:'Bohužel se nepovedlo uložit nový objekt! Zkuste to prosím znovu, nebo kontaktujte podporu',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(object)
            })
        }
        return object
    }

    setPage(page) {
        this.updateFilter("page", page)
    }

    build_pagination() {
        const items = []
        const pages = Math.ceil(this.state.server_count / this.state.limit) + 1
        // Let's start the pagination in the middle
        let start = this.state.filter.page - Math.floor(PAGES_VISIBLE/2)
        if (start + PAGES_VISIBLE > pages) {
            start-=(start+PAGES_VISIBLE - pages)
        }
        // Make sure we start at 1
        start = start >= 1 ? start : 1
        let end = Math.min(start + PAGES_VISIBLE, pages)

        for (let i=start; i<end;++i) {
            items.push(<Pagination.Item key={i} active={i === this.state.filter.page} onClick={() => {this.setPage(i)}} >
                    {i}
            </Pagination.Item>)
        }

        return <Pagination className='justify-content-center'>
            <Pagination.Item onClick={() => {this.setPage(this.state.filter.page -1)}} disabled={this.state.filter.page === 1}><ArrowLeftShort /></Pagination.Item>
            <Pagination.Item onClick={() => {this.setPage(1)}} disabled={this.state.filter.page === 1}><ChevronDoubleLeft /></Pagination.Item>
            {items}
            <Pagination.Item onClick={() => {this.setPage(pages - 1)}} disabled={this.state.filter.page === pages - 1}><ChevronDoubleRight /></Pagination.Item>
            <Pagination.Item onClick={() => {this.setPage(this.state.filter.page +1)}} disabled={this.state.filter.page === pages - 1}><ArrowRightShort /></Pagination.Item>
        </Pagination>
    }


    async updateFilter(name, value) {
        const filter = this.state.filter
        if (value !== undefined) {
            filter[name] = value
        }
        else {
            delete filter[name]
        }
        if (name !== "page") {
            filter['page'] = 1
        }
        await this.updateFilterBulk(filter)
        this.loadData(true)
    }

    itemDetail(item) {
        this.makeHistoryEntry()
        !this.noDetailView && this.props.history.push(this.props.history.location.pathname + "/" + encodeURIComponent(item.id))
    }

    renderFilter(filter) {
        const value = this.state.filter[filter.name]
        if (filter.type === "checkbox") {
            const state = value ? "checked" : ""
            return <Form.Group key={`filter-${filter.name}`} controlId={`filter-${filter.name}`} className="mb-0 px-2 pt-3">
                <Form.Check type="checkbox" label="Jenom mé objednávky" checked={state} onChange={() => {
                    this.updateFilter(filter.name, (state ? undefined: filter.value ))
                }}/>                                                        
            </Form.Group>
        }
        else if (filter.type === "select") {
            return <Form.Group key={`filter-${filter.name}`} controlId={`filter-${filter.name}`} className="pt-2">
                <Form.Control as="select" value={value} custom onChange={ev => this.updateFilter(filter.name, ev.target.value)}>
                    {(filter.values || filter.getValues()).map(item => {
                            return <option key={item.value} value={item.value}>{item.display_name}</option> 
                    })}
                </Form.Control>
            </Form.Group>
        }
        console.warn("Unknown type of filter!", filter)
        return null
    }

    async searchChanged(search) {
        search !== undefined && this.setState({search})
        this.updateFilterBulk({...this.state.filter, page: 1})
        if (this.search_running) {
            clearTimeout(this.search_running)
        }
        this.search_running = setTimeout(() => {
            this.loadData()
            this.loadCount()
            this.search_running = false}, SEARCH_COOLDOWN)
    }

    highlight(item) {
        // item represents an object of the row
    }

    toggleWide() {
        const wide = !this.state.wide
        this.setState({
            wide
        })
        localStorage.setItem(window.location.href + '-wide', wide)
    }

    onSortClicked(name) {
        const order_by = this.state.filter.order_by
        // This filter is already active
        if (this.isOrderingBy(name)) {
            name = "-"+name
        }
        this.updateFilter("order_by", [name])
    }

    isOrderingBy(name, reverseToo) {
        const order_by = this.state.filter.order_by
        if (!order_by) return false
        if (order_by === name || (Array.isArray(order_by) && order_by.indexOf(name) >= 0)) return true;
        return !!reverseToo && this.isOrderingBy("-" + name)
    }

    async applyFilters(filters) {
        if (!this.klass.filter_by) {
            MessageCenter.addMessage({title: "Zde nelze aplikovat filtry"})
            return
        }
        const new_filter = {
            ...this.state.filter,
            page: 1
        }
        Object.keys(this.klass.filter_by).forEach(key => {
            const value = filters[key]
            if (value === undefined) {delete new_filter[key]}
            else if (value === null) {new_filter[key] = ""}
            else if (typeof value === "object") {new_filter[key] = value.id}
            else new_filter[key] = value
        })
        await this.updateFilterBulk(new_filter)
        this.loadData()
    }

    rowClicked(e, value) {
        //Fix for nested buttons
        if (e.target.parentElement === e.currentTarget) this.itemDetail(value)
    }

    render() {
        const sidebar = !this.left_sidebar ? null : <div id="sidebar">
            {this.left_sidebar}
        </div>
        if (this.state.options === undefined)  {
            return <Container>
                <LoadingBanner />
            </Container>
        }
        const columns = this.state.wide ? this.klass.wide_columns : this.klass.columns || this.defaultColumns()
        return (
            <>
                {sidebar}
                <Container fluid={this.props.wideEnforce || this.state.wide}>
                    <div className="itemview">
                        <Container>
                        {(this.jumbotronTitle || this.props.jumbotronTitle || this.jumbotronSubtitle || this.props.jumbotronSubtitle ) && <Jumbotron className="mb-0 py-3">
                            <Row>
                                <Col md={this.extra_buttons.length === 0 ? 12 : 10}>
                                    <h1>{this.jumbotronTitle || this.props.jumbotronTitle}</h1>
                                    <p>
                                        {this.jumbotronSubtitle || this.props.jumbotronSubtitle}
                                    </p>
                                </Col>
                                {
                                    this.extra_buttons.length > 0 && <Col md={2}>
                                        {this.extra_buttons}
                                    </Col> 
                                }
                            </Row>
                        </Jumbotron>}
                        <ButtonToolbar className="align-items-center mb-0">      
                            <ButtonGroup>
                                {this.new_button === undefined ? <ModalNew placeholder={this.modalNewPlaceholder || this.props.modalNewPlaceholder}
                                    label={this.modalNewLabel || this.props.modalNewLabel}
                                    title={this.modalNewTitle || this.props.modalNewTitle}
                                    showText={this.modalNewLabel || this.props.modalNewLabel}
                                    ref={this.modal_ref}

                                    closeCallback={() => {this.new()}} 
                                    /> : typeof(this.new_button) === "function" ? this.new_button() : this.new_button}
                            </ButtonGroup>                  
                            { this.help && <ButtonGroup className="ml-2">
                                <HelpComponent helpTopic={this.helpTopic}>{ this.help }</HelpComponent>
                            </ButtonGroup> }
                            { this.filters && <ButtonGroup className="ml-2">
                                {this.filters.map(item => {return this.renderFilter(item)})}
                            </ButtonGroup>}
                            { this.extra_actions.map((i, num) => {return <React.Fragment key={num}>{i}</React.Fragment>})}
                            <Form className="ml-auto btn-group input-group">
                                <input type="text" value={this.state.search} className="form-control" placeholder="Hledat" onChange={(event) => {this.searchChanged(event.target.value)}} />
                                <Button variant="secondary" className="flex-grow-0">
                                    {!this.search_running ? <Search /> : 
                                        <Spinner animation="border" role="status" size="sm">
                                            <span className="sr-only">Saving...</span>
                                        </Spinner>}
                                </Button>
                            </Form>
                            <ButtonGroup className="ml-1">
                                {this.klass.wide_columns && <Button onClick={() => this.toggleWide()}variant="outline-secondary">
                                    {!this.state.wide ? <ArrowsFullscreen /> : <FullscreenExit />}
                                </Button> /* Only if model has wide_columns defined */}
                                <FilterModal klass={this.klass} activeFilter={this.state.filter} onChange={filters => this.applyFilters(filters)} />
                            </ButtonGroup>
                        </ButtonToolbar>
                        </Container>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {!this.columnsGenerator && columns.map(item => {
                                        return <th key={item.name}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                {item.label}
                                                {item.sortable !== false && <span style={{cursor: "pointer"}}
                                                                                onClick={() => this.onSortClicked(item.name)}
                                                                                className={this.isOrderingBy(item.name, true) ? "" : "text-muted"}>
                                                                                {this.isOrderingBy("-" +item.name) ? <SortDown /> : <SortUp />}
                                                </span>}
                                            </div>
                                        </th>
                                    })}
                                    {!this.columnsGenerator && this.show_controls && <th>Ovládání</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.queryset.objects.map((value, index) => {
                                    const highlight = this.highlight(value)
                                    const options = this.state.options
                                    const row = <tr key={index} style={{...{cursor:"pointer"}, ...(!highlight ? {} : {background: "#F4CCCC"})}} onClick={e => this.rowClicked(e, value)}>
                                        {!this.columnsGenerator && columns.map(item => {
                                            /*display = display || (""+value[item.name]).toLowerCase().search(this.state.search.toLowerCase()) !== -1*/
                                            return <td key={item.name + "-" + value.id}>
                                                {!item.transform ? value[item.name] : item.transform(value[item.name], options, value)}
                                            </td>
                                        })}
                                        {!this.columnsGenerator && this.show_controls && <td>
                                        {Account.current().level > 0 && this.enable_delete && <Button size="sm" variant="outline-danger" onClick={(event) => {event.stopPropagation(); this.deleteEntry(value)}}>
                                            <Trash />
                                        </Button>}
                                        {this.extra_controls.map(control => {
                                            const props = Object.fromEntries((control.stateToProperties || []).map(item => [item, this.state[item]]))
                                            return <control.klass key={control.klass.constructor.name} {...props} {...(control.extraProperties || {})} row_item={value}>
                                                {control.children}
                                            </control.klass>
                                        })}
                                        </td>}
                                        {/* Column generator can be used for generating the columns in the ModelView child */}
                                        {this.columnsGenerator && this.columnsGenerator(value, options)}
                                    </tr>
                                    return row 
                                })}
                                {this.state.queryset.count < 1 && <tr>
                                    <td className="text-center" colSpan={columns.length + this.show_controls}><strong>{this.state.queryset.count === 0 ? "Bez výsledků" : "Na výsledky čekáme"}</strong></td>
                                </tr>}
                            </tbody>
                        </Table>
                        {this.state.queryset.count === -1 || !this.state.server_count ? null : 
                                this.state.queryset.count === this.state.server_count ? null : this.build_pagination()}
                        {this.state.queryset.count !== -1 &&<Row>
                            <Col sm="6">
                            {this.state.server_count?.length > 0 && this.state.queryset.count !== this.state.server_count && 
                                <p>{this.state.limit ? <em>Záznamů na stránku: {this.state.limit}</em> : ""}</p>}
                            </Col>
                            <Col sm="6" className="text-right">
                                <p>
                                    {!!this.state.server_count &&
                                        <em>
                                            Celkový počet načtených záznamů:&nbsp;
                                            {this.state.server_count}
                                            (stránek: {Math.ceil(this.state.server_count / this.state.limit)})
                                        </em>}
                                </p>
                            </Col>
                        </Row>}
                    </div>
                </Container>
            </>
        );
    }
}

export default ModelView;

import MenuItem from "./Classes/MenuItem"
import Menu from "./Classes/Menu"
import React from 'react'
import UserView from './Views/UserView'
import CustomerView from './Views/CustomerView'
import CustomerDetail from './Views/CustomerDetail'
import OrderDetail from './Views/OrderDetail'
import OrderView from './Views/OrderView'
import CarrierView from './Views/CarrierView'
import CarrierDetail from './Views/CarrierDetail'
import ReportView from "./Views/ReportView"
import CurrencyView from "./Views/CurrencyView"
import OrderImportView from "./Views/OrderImportView"
import EmailTemplateView from "./Views/EmailTemplateView"
import EmailTemplateDetail from "./Views/EmailTemplateDetail"
import SnippetView from "./Views/SnippetView"
import SnippetDetail from "./Views/SnippetDetail"
import LabelView from "./Views/LabelView"
import SettingsView from "./Views/SettingsView"
import Account from "./Classes/Account"
import OrderFilterView from "./Views/OrderFilterView"
import PlaceView from "./Views/PlaceView"
import VehicleView from "./Views/VehicleView"
import PlaceDetail from "./Views/PlaceDetail"
import SupportMailView from "./Views/SupportMailView"
import VehicleDetail from "./Views/VehicleDetail"
import VehicleOverview from './Views/VehicleOverview'
import { ReportingView } from "./Views/OrderReportingView"
import ExpenseView from "./Views/ExpenseView"
import { ExpenseDetail } from "./Views/ExpenseDetail"
import { Building, Buildings, CashStack, GeoAlt, Globe, Receipt, Truck } from "react-bootstrap-icons"
import { LabelDetail } from "./Views/LabelDetail"
import { AgendaView } from "./Views/AgendaView"
import { DailyLocationView } from "./Views/DailyLocationView"
import RequirementsView from "./Views/RequirementsView"
import { RequirementsDetail } from "./Views/RequirementDetail"
import { AccountingView } from "./Views/AccountingView"
import InvoiceView from "./Views/InvoiceView"
import { InvoiceDetail } from "./Views/InvoiceDetail"
import { InvoiceConnector } from "./Views/InvoiceConnector"
import { Alert, Container } from "react-bootstrap"
import CompanyIdentityView from "./Views/CompanyIdentityView"
import { CompanyIdentityDetail } from "./Views/CompanyIdentityDetail"
import OrderEditor from "./Components/OrderEditor"

export default function generateMenu(config) {
    const manual_saving = config?.order_manual_saving
    const menu = [
        new Menu("Přehledy", [
            new MenuItem({
                "label": "Agenda",
                "path": "/agenda/",
                "renders": <AgendaView />
            }),
            new MenuItem({
                "label": "Statistiky",
                "path": "/reporting/",
                "renders": <ReportingView />
            }),
            new MenuItem({
                "label": "Denní přehled",
                "path": "/daily/",
                "renders": <DailyLocationView />
            })
            /*        new MenuItem({
                        "label": "Měsíční agenda",
                        "path": "/order-agenda/",
                        "renders": <OrderAgendaView />
                    }),*/
        ], ""),
        new Menu(<><Receipt /> Objednávky</>, [
            new MenuItem({
                "label": "Přehled",
                "path": "/order",
                "renders": <OrderView />,
                "detailView": manual_saving ? <OrderEditor /> : <OrderDetail />
            }),
            new MenuItem({
                "label": "Filtrování",
                "path": "/order-filter/",
                "renders": <OrderFilterView />
            }),
        ], ""),
        new MenuItem({
            "label": <><Building /> Zákazníci</>,
            "path": "/customer",
            "renders": <CustomerView modalNewPlaceholder="Jméno zákazníka"
                modalNewLabel="Nový zákazník"
                modalNewTitle="Přidání nového zákazníka"
                jumbotronTitle="Zákazníci"
            />,
            "detailView": <CustomerDetail />
        }),
        new MenuItem({
            "path": "/orderimport",
            "renders": <OrderImportView />
        }),
        new MenuItem({
            "path": "/order-filter",
            "renders": <OrderFilterView />
        }),
        new MenuItem({
            "label": <><Globe /> Dopravci</>,
            "path": "/carrier",
            "renders": <CarrierView
                modalNewPlaceholder="Jméno dopravce"
                modalNewLabel="Nový dopravce"
                modalNewTitle="Přidání nového dopravce"
                jumbotronTitle="Dopravci"
            />,
            "detailView": <CarrierDetail />
        }),
        new Menu(<><Truck /> Vozidla</>, [
            new MenuItem({
                "label": "Správa",
                "path": "/vehicle",
                "renders": <VehicleView />,
                "detailView": <VehicleDetail />
            }),
            new MenuItem({
                "label": "Přehled",
                "path": "/vehicle-overview",
                "renders": <VehicleOverview />
            })
        ], ""),
        new MenuItem({
            "label": "",
            "path": "/report",
            "renders": <ReportView />
        }),
        new MenuItem({
            "label": "",
            "path": "/support",
            "renders": <SupportMailView />
        }),
    ]

    if (Account.isLogged() && Account.current().is_superuser) {
        menu.push(new MenuItem({
            "label": <><CashStack /> Výdaje</>,
            "path": "/expense",
            "renders": <ExpenseView
                jumbotronTitle="Výdaje"
                jumbotronSubtitle="Pokladní knížka pro výdaje"
            />,
            "detailView": <ExpenseDetail />
        }))
    }

    if (config?.accounting) {
        if (Account.isLogged() && Account.current().hasPerm("accounting")) {
            menu.push(new Menu(<><Receipt /> Účetnictví</>, [
                new MenuItem({
                    "label": "Fakturace",
                    "path": "/accounting/",
                    "renders": <AccountingView />
                }),
                new MenuItem({
                    "label": "Propojení faktur",
                    "path": "/invoice-connector",
                    "renders": <InvoiceConnector />
                }),
                new MenuItem({
                    "label": "Faktury",
                    "path": "/invoice",
                    "renders": <InvoiceView wideEnforce={true} />,
                    "detailView": <InvoiceDetail />
                })
            ], ""))
        }
        else {
            menu.push(new MenuItem({
                "label": "",
                "path": "/invoice",
                "renders": <Container>
                    <Alert variant="danger">
                        <strong>Pro fakturaci nemáte potřebná oprávnění</strong>
                    </Alert>
                </Container>
            }))
        }
    } else {
        menu.push(new MenuItem({
            "label": "",
            "path": "/invoice",
            "renders": <Container>
                <Alert variant="danger">
                    <strong>Fakturace není aktivovaná. Kontaktujte vašeho administrátora</strong>
                </Alert>
            </Container>
        }))
    }
    Account.isLogged() && Account.current().hasPerm("view_places") && menu.push(new MenuItem({
        "label": <><GeoAlt /> Místa</>,
        "path": "/places",
        "renders": <PlaceView />,
        "detailView": <PlaceDetail />
    }))

    if (Account.isLogged() && Account.current().is_superuser) {
        menu.push(new Menu("Správa systému",
            [
                new MenuItem({
                    "label": <><Buildings /> Identity společnosti</>,
                    "path": "/companyidentities",
                    "renders": <CompanyIdentityView />,
                    "detailView": <CompanyIdentityDetail />,
                }),
                new MenuItem({
                    "label": "Šablony e-mailů",
                    "path": "/emailtemplate",
                    "renders": <EmailTemplateView />,
                    "detailView": <EmailTemplateDetail />
                }),
                new MenuItem({
                    "label": "Útržky",
                    "path": "/snippet",
                    "renders": <SnippetView />,
                    "detailView": <SnippetDetail />
                }),
                new MenuItem({
                    "label": "Štítky",
                    "path": "/label",
                    "renders": <LabelView />,
                    "detailView": <LabelDetail />
                }),
                new MenuItem({
                    "label": "Přehled měn",
                    "path": "/currency",
                    "renders": <CurrencyView />
                }),
                new MenuItem({
                    "label": "Nastavení systému",
                    "path": "/settings",
                    "renders": <SettingsView />
                }),
                new MenuItem({
                    "label": "Uživatelé",
                    "path": "/user",
                    "renders": <UserView />
                }),
                new MenuItem({
                    "label": "Požadavky",
                    "path": "/requirements",
                    "renders": <RequirementsView />,
                    "detailView": <RequirementsDetail />,
                })
            ]
        ))
    }
    return menu
}

import React, { useContext, useState } from "react"
import { Button, Dropdown, Modal, Row, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ClipboardCheck, QuestionCircle } from "react-bootstrap-icons"
import Carrier from "../../Classes/Carrier"
import Customer from "../../Classes/Customer"
import Vehicle from "../../Classes/Vehicle"
import DiffTable from "../DiffTable"
import { OrderEditorContext } from "./context"


export function CopyModal(props) {
	const [show, setShow] = useState(false)
	const { order, changes, saving, setSaving, copyOrder, quickSaveOrder, options } = useContext(OrderEditorContext);

	async function copyWithChanges() {
		if (copyOrder()) {
			setShow(false);
		}
	}

	async function saveAndCopy() {
		await quickSaveOrder();
		await copyWithChanges()
	}

	async function triggerCopyModal() {
		if (changes === null || Object.keys(changes).length === 0) {
			await copyOrder();
			return;
		}
		else {
			setShow(true);
		}
	}

	return <>
		<Dropdown.Item target="_blank" onClick={triggerCopyModal}><ClipboardCheck /> Kopírovat</Dropdown.Item>
		<Modal show={show} onHide={() => setShow(false)} centered={true} size="lg">
			<Modal.Header>
				<Modal.Title>Pozor! Neuložené změny</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<DiffTable source={order} changes={changes} options={options} converters={{
					carrier: (v) => v ? Carrier.prototype.label.call(v) : '',
					customer: (v) => v ? Customer.prototype.label.call(v) : '',
					vehicle: (v) => Vehicle.prototype.label.call(v),
					vehicle_carrier: (v) => v ? v.name : '',
					vehicle_type: (v) => v ? v.name : ''
				}} />
			</Modal.Body>
			<Modal.Footer>
				{!saving ? <div className="d-flex justify-content-between w-100">
					<div>
						<Button variant="outline-primary" onClick={copyWithChanges} className="mr-1">Přenést změny do kopie</Button>
						<Button variant="primary" onClick={saveAndCopy} className="mr-1">Uložit změny a pak kopírovat</Button>
					</div>
					<div>
						<Button variant="secondary" onClick={() => setShow(false)}>Storno</Button>
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip id="button-tooltip">
									<p><strong>Přenést změny do kopie:</strong> Přenese aktuální změny do nové kopie objednávky.</p>
									<p><strong>Uložit změny a pak kopírovat:</strong> Uloží aktuální změny a vytvoří novou kopii objednávky.</p>
									<p><strong>Storno:</strong> Zavře toto okno bez uložení nebo kopírování - o změny nepřijdete</p>
								</Tooltip>
							}
						>
							<QuestionCircle style={{ cursor: 'pointer', marginLeft: '10px' }} />
						</OverlayTrigger>
					</div>
				</div> :
					<div><Spinner animation="border" /></div>}
			</Modal.Footer>
		</Modal >
	</>
}
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import { OrderEditorContext, OrderEditorProvider } from './context.js'
import React, { useContext, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Container, Row, Alert, Form, InputGroup } from 'react-bootstrap';
import OrderEditorSidebar from './Sidebar.js';
import CompanyIdentity from '../../Classes/CompanyIdentity.js';
import SearchModal from '../SearchModal.js';
import { ArrowReturnLeft, Building, Search, Trash, Truck, Globe, FileEarmarkPerson, BodyText, CircleFill } from 'react-bootstrap-icons';
import Customer from '../../Classes/Customer.js';
import Carrier from '../../Classes/Carrier.js';
import Vehicle from '../../Classes/Vehicle.js';
import LocationsEditor from './LocationsEditor.js';

import RichTextEditor from '../RichTextEditor';
import { convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { DraftJSexportOptions, DraftJSimportOptions } from '../../Helpers/draftHTMLhelpers';
import OrderTopBar from './OrderTopBar.js';
import { OrderRequirements } from './OrderRequirements.js';
import DiffTable from '../DiffTable.js';
import Order from '../../Classes/Order';
import { getOptions } from '../../Classes/Base.js';
import { AutocompleteInput } from '../AutocompleteInput.js';

function OrderEditorContent() {
    const { order, options, currencies, users, companyIdentities, readOnly, changes, setChanges, getCurrentValue, reloadOrder } = useContext(OrderEditorContext);
    const [activeTab, setActiveTab] = useState('normal');
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    function updateValues(updates) {
        setChanges({ ...changes, ...updates });
    }

    let customer = getCurrentValue('customer');
    let carrier = getCurrentValue('carrier');
    let vehicle = getCurrentValue('vehicle');
    let requirements = getCurrentValue('requirements');

    const pallets = getCurrentValue('pallets');
    const weight = getCurrentValue('weight');

    return (
        <div className='w-100'>
            <OrderTopBar sidebarCollapsed={sidebarCollapsed} toggleSidebar={() => setSidebarCollapsed(false)} />
            <Container style={{ maxWidth: '1360px' }}>
                <Row>
                    {/* Sidebar should be collapsible*/}
                    <Col md={4} lg={2} sm={2} className={`h-100 bg-light flex-column flex-grow-1 ${sidebarCollapsed ? 'd-none' : ''}`}>
                        {!sidebarCollapsed && (
                            <OrderEditorSidebar {...order} options={options} currencies={currencies} users={users} collapse={() => setSidebarCollapsed(true)} />
                        )}
                    </Col>
                    <Col md={sidebarCollapsed ? 12 : 8} lg={sidebarCollapsed ? 12 : 10} sm={6} className='h-100 bg-white flex-column flex-grow-1'>
                        <Row>
                            <Col md={5}>
                                {/* Company Identity handling */}
                                {companyIdentities.count > 0 && <Card className='mb-2'>
                                    <Card.Body className='d-flex justify-content-between align-items-center p-1 px-3'>
                                        <div><strong>Identita společnosti: </strong>
                                            {order.company_identity?.name} {order.company_identity?.default && <em>(výchozí)</em>}
                                        </div>
                                        <ButtonGroup>
                                            <SearchModal
                                                klass={CompanyIdentity}
                                                closeCallback={company_identity => updateValues({ company_identity })}
                                            />
                                            {order.company_identity?.id > 0 && <Button
                                                variant="secondary"
                                                label="Použít výchozí identitu"
                                                onClick={() => updateValues({ company_identity: null })}
                                            >
                                                <ArrowReturnLeft />
                                            </Button>}
                                        </ButtonGroup>
                                    </Card.Body>
                                </Card>}
                                {/* Customer handling */}
                                <Card className="mb-2">
                                    <Card.Header className="d-flex justify-content-between py-1">
                                        <span><Building /> Zákazník</span>
                                        {customer && <ButtonGroup size='sm'>
                                            <Button size="sm" variant="outline-primary"
                                                href={"/customer/" + customer.id}><Search /></Button>
                                            <Button size="sm" variant="outline-danger" onClick={() => updateValues({ customer: null })}><Trash /></Button>
                                        </ButtonGroup>}
                                    </Card.Header>
                                    <Card.Body className='pb-0'>
                                        {customer?.blacklist && <Alert style={{ fontSize: "16px", padding: "3px 15px", margin: "0px" }} variant="danger">
                                            <strong>Zákazník je na černé listině</strong>
                                        </Alert>}
                                        <Card.Title>{customer?.name} </Card.Title>
                                        <Card.Text as="div">
                                            {!customer ?
                                                <div style={{ marginTop: "-15px", marginBottom: "15px" }} className='d-flex justify-content-center align-items-center'>
                                                    <SearchModal
                                                        label="Vybrat zákazníka"
                                                        variant="outline-primary"
                                                        klass={Customer}
                                                        fields={['ID', 'name']}
                                                        pkSearch={true}
                                                        createNew
                                                        closeCallback={
                                                            entry => (!entry.blacklist || window.confirm("Zákazník je na černé listině! Chcete ho přidat?")) && updateValues({ customer: entry, customer_order: "" })
                                                        } />
                                                </div> :
                                                <Form.Group>
                                                    <Form.Label>Číslo objednávky u zákazníka</Form.Label>
                                                    <Form.Control value={getCurrentValue('customer_order')} onChange={e => updateValues({ customer_order: e.target.value })} />
                                                </Form.Group>
                                            }
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                {/* Carrier handling */}
                                <Card className="mb-2">
                                    <Card.Header className="d-flex justify-content-between py-1">
                                        <span><Truck /> Doprava</span>
                                        {carrier && <ButtonGroup size='sm'>
                                            <Button size="sm" variant="outline-primary"
                                                href={"/carrier/" + carrier.id}><Search /></Button>
                                            <Button size="sm" variant="outline-danger" onClick={() => updateValues({ carrier: null })}><Trash /></Button>
                                        </ButtonGroup>}
                                    </Card.Header>
                                    <Card.Body className="pt-2 pb-2">
                                        <Card.Text as="div" className='d-flex justify-content-between flex-column'>
                                                {carrier && <Card.Title>
                                                    <div className='d-flex justify-content-between flex-grow-1 align-items-center'>
                                                        <div>{carrier?.name}</div>
                                                        {carrier?.blacklist && <Alert style={{ fontSize: "16px", padding: "3px 15px", margin: "0px" }} variant="danger">
                                                            <strong>Dopravce je na černé listině</strong>
                                                        </Alert>}
                                                    </div>
                                                </Card.Title>}
                                                {
                                                    carrier ?
                                                        (carrier.street || carrier.house_number ||
                                                            carrier.town || carrier.postal_code ||
                                                            carrier.country) && <div>
                                                            {carrier.street}&nbsp;{carrier.house_number},{' '}
                                                            {carrier.town} {carrier.postal_code}, {carrier.country}
                                                        </div>
                                                        : vehicle ?
                                                            <div>
                                                                Vozidlo - {vehicle.spz}<br />
                                                                <Globe /> {vehicle.carrier ? vehicle.carrier.name : <em>Vozidlo nemá dopravce</em>}
                                                            </div>
                                                            :
                                                            <div className="d-flex align-items-center w-100">
                                                                <ButtonGroup className='m-auto'>
                                                                    <SearchModal label="Vybrat dopravce" variant="outline-primary" klass={Carrier} fields={['ID', 'name', 'ico', 'blacklist']} pkSearch={true} createNew={["name", "ico", "raal"]}
                                                                        closeCallback={entry => { if (!entry.blacklist || window.confirm(`Dopravce ${entry.name} je na černé listině, opravdu ho chcete použít?`)) updateValues({ carrier: entry }) }} />
                                                                    <SearchModal label="Vybrat vozidlo" variant="outline-success" klass={Vehicle} fields={['id', 'spz', 'type']} pkSearch={true} createNew
                                                                        closeCallback={entry => { updateValues({ vehicle: entry }) }} />
                                                                </ButtonGroup>
                                                            </div>
                                                }
                                                {
                                                    carrier && <div className='border-top mt-2 pt-2'>
                                                        <InputGroup>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>Dní splatnosti objednávky</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="number" min="0"
                                                                value={getCurrentValue('due_days')}
                                                                onChange={e => updateValues({ due_days: e.target.value })}
                                                            />
                                                        </InputGroup>
                                                        <InputGroup>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>Faktura dopravce</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control
                                                                value={getCurrentValue('carrier_invoice')}
                                                                disabled={getCurrentValue('carrier_missing_invoice')}
                                                                onChange={e => updateValues({ carrier_invoice: e.target.value })}
                                                            />
                                                        </InputGroup>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className='text-muted'>
                                                                {order.carrier_invoice_update && <small>
                                                                    Datum zadání faktury: {new Date(order.carrier_invoice_update).toLocaleDateString()}
                                                                </small>}
                                                            </div>
                                                            <Form.Check type="checkbox" label="Bez faktury"
                                                                disabled={order.carrier_invoice}
                                                                checked={getCurrentValue('carrier_missing_invoice')}
                                                                onChange={e => updateValues({ carrier_missing_invoice: e.target.checked, carrier_invoice: '' })}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                {/* Vehicle handling */}
                                <Card className="mb-2">
                                    <Card.Header className="py-1">
                                        <FileEarmarkPerson /> Vozidlo a posádka
                                    </Card.Header>
                                    <Card.Body className="py-1">
                                        <Row className="mb-1">
                                            <Form.Label className="col-form-label col-sm-6">Typ vozu:</Form.Label>
                                            <InputGroup className="col-sm-6">
                                                {/*<Form.Control readOnly={readOnly} value={getCurrentValue('vehicle_type')} onChange={e => updateValues({ vehicle_type: e.target.value })} />*/}
                                                <AutocompleteInput
                                                        loadAutocomplete={async (value) => {
                                                            return (await Order.autocomplete("vehicle_type", value)).values.map(i => {
                                                                return i.vehicle_type
                                                            })
                                                        }}
                                                        value={getCurrentValue("vehicle_type")}
                                                        itemSelected={vehicle_type => { updateValues({vehicle_type}) }}
                                                    />
                                            </InputGroup>
                                        </Row>
                                        <Row className="mb-1">
                                            <Form.Label className="col-form-label col-sm-6">Registrační značka:</Form.Label>
                                            <InputGroup className="col-sm-6">
                                                <Form.Control readOnly={readOnly} value={getCurrentValue('license_plate')} onChange={e => updateValues({ license_plate: e.target.value })} />
                                            </InputGroup>
                                        </Row>
                                        <Row className="mb-1">
                                            <Form.Label className="col-form-label col-sm-6">Jméno řidiče:</Form.Label>
                                            <InputGroup className="col-sm-6">
                                                <Form.Control readOnly={readOnly} value={getCurrentValue('driver_name')} onChange={e => updateValues({ driver_name: e.target.value })} />
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Label className="col-form-label col-sm-6">Telefon na řidiče:</Form.Label>
                                            <InputGroup className="col-sm-6">
                                                <Form.Control readOnly={readOnly} value={getCurrentValue('driver_phone')} onChange={e => updateValues({ driver_phone: e.target.value })} />
                                            </InputGroup>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                {/* Pallets & Weight */}
                                <Card className="mb-2">
                                    <Card.Header className="py-1">
                                        <BodyText /> Palety a váha
                                    </Card.Header>
                                    <Card.Body className="py-1">
                                        <Row className="mb-1">
                                            <Form.Label className="col-form-label col-sm-6">Počet palet:</Form.Label>
                                            <InputGroup className="col-sm-6">
                                                <Form.Control type="number" readOnly={readOnly} value={pallets !== null ? pallets : ""} onChange={e => updateValues({ pallets: e.target.value })} />
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Label className="col-form-label col-sm-6">Celková váha:</Form.Label>
                                            <InputGroup className="col-sm-6">
                                                <Form.Control type="number" readOnly={readOnly} value={weight !== null ? weight : ""} onChange={e => updateValues({ weight: e.target.value })} />
                                            </InputGroup>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={7}>
                                <LocationsEditor locations={order.locations} locationsUpdated={reloadOrder} distance={order.distance} updateDistance={console.log} />
                                <hr />
                                {/* Requirements */}
                                <Card className="mt-2">
                                    <Card.Header className='py-1'>Požadavky</Card.Header>
                                    <Card.Body className='py-1'>
                                        <OrderRequirements requirements={requirements} requirementsChanged={requirements => updateValues({requirements})} />
                                    </Card.Body>
                                </Card>
                                {/* Notes in custom tabbed view */}
                                <div className="custom-tabs border rounded mt-2">
                                    <div className='d-flex justify-content-between align-items-center mb-2 px-3 pt-2 bg-light'>
                                        <strong>Poznámky</strong>
                                        <ButtonGroup size='sm'>
                                            <Button variant={activeTab === 'normal' ? 'primary' : 'outline-primary'} onClick={() => setActiveTab('normal')}>
                                                Normální {activeTab !== 'normal' && order.notes && <CircleFill className="text-danger ml-1" size={10} />}
                                            </Button>
                                            <Button variant={activeTab === 'internal' ? 'primary' : 'outline-primary'} onClick={() => setActiveTab('internal')}>
                                                Interní {activeTab !== 'internal' && order.internal_notes && <CircleFill className="text-danger ml-1" size={10} />}
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    <div className="tab-content">
                                        {activeTab === 'normal' && (
                                            <RichTextEditor
                                                editorState={EditorState.createWithContent(stateFromHTML(order.notes, DraftJSimportOptions))}
                                                features={{
                                                    small_buttons: true,
                                                    load_changes: false,
                                                    formats: false,
                                                }}
                                                setEditorState={(e) => {
                                                    if (convertToRaw(stateFromHTML(order.notes, DraftJSimportOptions)) !== convertToRaw(e.getCurrentContent())) {
                                                        updateValues({ notes: stateToHTML(e.getCurrentContent(), DraftJSexportOptions) })
                                                    }
                                                }}
                                            />
                                        )}
                                        {activeTab === 'internal' && (
                                            <RichTextEditor
                                                editorState={EditorState.createWithContent(stateFromHTML(order.internal_notes, DraftJSimportOptions))}
                                                features={{
                                                    small_buttons: true,
                                                    load_changes: false,
                                                    formats: false,
                                                }}
                                                setEditorState={(e) => {
                                                    if (convertToRaw(stateFromHTML(order.internal_notes, DraftJSimportOptions)) !== convertToRaw(e.getCurrentContent())) {
                                                        updateValues({ internal_notes: stateToHTML(e.getCurrentContent(), DraftJSexportOptions) })
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function OrderEditor(props) {
    const { id } = useParams();

    return (
        <OrderEditorProvider orderId={id}>
            <OrderEditorContent />
        </OrderEditorProvider>
    );
};

export default OrderEditor;
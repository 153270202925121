import React, { useContext, useState } from 'react';
import { Card, Button, ButtonGroup, Row, Col, Alert, InputGroup, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BoxArrowInUpRight, BoxArrowDownRight, CashCoin, QuestionCircle, Trash, Pencil, ArrowUpShort, ArrowDownShort, ExclamationTriangle, Calculator, Search, Question } from 'react-bootstrap-icons';
import { OrderEditorContext } from './context';
import Location from '../../Classes/Location';
import LocationModalEditor from './LocationModalEditor';
import MessageCenter from '../MessageCenter';

function i18n_date(date) {
    return new Intl.DateTimeFormat("cs-CZ", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }).format(new Date(date));
}

function formattedAddress(place) {
    return place && (place.street || place.house_number || place.town || place.postal_code || place.country) ? <>
        {place.street && <>{place.street} {place.house_number} <br /></>}
        {(place.town || place.postal_code) && <>{place.town} {place.postal_code} <br /></>}
        {place.country && <>{place.country} <br /></>}
    </> : <strong>Adresa není vyplněná!</strong>;
}

function formattedLocationContact(location) {
    return location.name || location.phone || location.email || location.code ? <>
        {location.name && <>{location.name} <br /></>}
        {location.phone && <>{location.phone} <br /></>}
        {location.email && <>{location.email} <br /></>}
        {location.code && <strong>CODE: {location.code} <br /></strong>}
    </> : <strong>Kontakt není vyplněný!</strong>;
}

function LocationsEditor(props) {
    const { locations } = props;
    const { order, getCurrentValue, setChanges, changes } = useContext(OrderEditorContext);
    const [editingLocation, setEditingLocation] = useState(null);

    const distance = getCurrentValue('distance');

    function updateValues(values) {
        setChanges({ ...changes, ...values });
    }

    async function deleteLocation(location) {
        if (location?.id === undefined) {
            MessageCenter.addMessage({"title": "Nepodařilo se identifikovat zastávku", "message": "Zastávka podle indexu nešla najít, nelze ji smazat. Toto by se němelo stát"});
            return
        }
        try {
            if (!await location.delete())
                throw (new Error("Nepodařilo se smazat zastávku"));
            props?.locationsUpdated && props.locationsUpdated();
        }
        catch (error) {
            console.error(error);
            MessageCenter.addMessage({"title": "Nepodařilo se smazat zastávku", "message": "Zastávka se nepodařilo smazat, zkuste to prosím znovu"});
        }
    }

    async function moveLocation(location, position) {
        
        if (location?.id === undefined) {
            MessageCenter.addMessage({"title": "Nepodařilo se identifikovat zastávku", "message": "Zastávka podle indexu nešla najít, nelze ji přesunout. Toto by se němelo stát"});
            return
        }
        try {
            if (!await Location.prototype.reorder.call(location, position))
                throw (new Error("Nepodařilo se přesunout zastávku"));
            props?.locationsUpdated && props.locationsUpdated();
        }
        catch (error) {
            console.error(error);
            MessageCenter.addMessage({"title": "Nepodařilo se přesunout zastávku", "message": "Zastávka se nepodařilo smazat, zkuste to prosím znovu"});
        }
        props?.locationsUpdated && props.locationsUpdated();
    }

    const handleSave = () => {
        // Logic to save the location
        props?.locationsUpdated && props.locationsUpdated();
        setEditingLocation({});
    };

    const handleCancel = () => {
        setEditingLocation({});
    };

    return (
        <div>
            <LocationModalEditor location={editingLocation} />
            {locations.map((location, index) => {
                const position = locations.length - 1 - index;
                return <div key={index} className="border rounded py-1 px-1 mb-2 bg-light">
                    <div className="d-flex justify-content-between">
                        <div style={{ fontSize: "1.5em", marginLeft: '3px' }}>
                            {location.type === "LOAD" ? <BoxArrowInUpRight />
                                : location.type === "UNLOAD" ? <BoxArrowDownRight />
                                    : location.type === "TOLL" ? <CashCoin />
                                        : <QuestionCircle />}
                        </div>
                        <div className="flex-grow-1">
                            <div className="text-center">
                                {Location.prototype.prettyName.call(location)}
                            </div>
                            <div className='text-center'>
                                {i18n_date(location.time)}
                                {location.end_time && location.time !== location.end_time && <> - {i18n_date(location.end_time)}</>}
                                {location.fixed_window && <span style={{ color: "red" }}>
                                    {" "}
                                    <ExclamationTriangle title='Fixní časové okno zastávky' />
                                    &nbsp;Fixní okno
                                </span>}
                            </div>
                        </div>
                        <ButtonGroup size="sm" className="locationButtonGroup">
                            <Button size="sm" variant="success" onClick={() => setEditingLocation(location)}><Pencil /></Button>
                            <Button size="sm" variant="danger" onClick={() => deleteLocation(location)}><Trash /></Button>
                            <div className='d-flex flex-column justify-content-between rounded'>
                                <div className={position + 1 < order.locations.length ? "reorder" : "reorder disabled"} title="Nahoru" onClick={() => moveLocation(location, position + 1)}>
                                    <ArrowUpShort />
                                </div>
                                <div className={position !== 0 ? "reorder" : "reorder disabled"} title="Dolů" onClick={() => moveLocation(location, position - 1)} disabled={position === 0}>
                                    <ArrowDownShort />
                                </div>
                            </div>
                        </ButtonGroup>
                    </div>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header className="py-1">Adresa</Card.Header>
                                <Card.Body className="py-1">
                                    {formattedAddress(location.place)}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header className="py-1">Kontakt</Card.Header>
                                <Card.Body className="py-1">
                                    {formattedLocationContact(location)}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {location.load && <div><strong>Náklad</strong> {location.load} </div>}
                    {location.notes && location.notes != '<p><br></p>' && <div dangerouslySetInnerHTML={{__html: "<strong>Poznámky</strong><br/>" + location.notes}}></div>}
                </div>
            })}
            <Row>
                <Col lg={6}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Form.Label className='mb-0 mr-2'>Vzdálenost</Form.Label>
                        <InputGroup>
                            <Form.Control type="number" value={distance === null ? "" : distance} onChange={(e) => updateValues({ distance: e.target.value === "" ? null : e.target.value })} />
                            <InputGroup.Append>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">
                                        {distance > 0 ? (
                                            <div>
                                                <div><strong>Marže / km:</strong><br /> {(order.margin / distance).toFixed(2)} {order.customer_currency?.code}</div>
                                                <div><strong>Cena zákazníka / km:</strong><br /> {(order.price_customer / distance).toFixed(2)} {order.customer_currency?.code}</div>
                                                <div><strong>Cena dopravce / km:</strong><br /> {(order.price_carrier / distance).toFixed(2)} {order.carrier_currency?.code}</div>

                                            </div>
                                        ) : "Pokud zadáte vzdálenost, zobrazí se zde marže a ceny za km."}
                                    </Tooltip>}
                                >
                                    <InputGroup.Text style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>
                                        km <Question style={{ marginLeft: '1px', fontSize: '1.2em', color: "blue" }} />
                                    </InputGroup.Text>
                                </OverlayTrigger>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>
                </Col>
                <Col lg={6}>
                                                                    {/*Current order and put in at the beginning*/}
                    <Button block onClick={() => setEditingLocation({id: 0, order: order?.id, position: 0})}>Přidat zastávku</Button>
                </Col>
            </Row>
            {editingLocation && (
                <LocationModalEditor
                    location={editingLocation}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
}

export default LocationsEditor;
import React, { createContext, useState } from 'react';
import { getOptions } from '../../Classes/Base.js';
import Order from '../../Classes/Order.js';
import Queryset from '../../Classes/Queryset.js';
import { Alert } from 'react-bootstrap';
import Currencies from '../../Classes/Currencies.js';
import Spinner from 'react-bootstrap/Spinner';
import User from '../../Classes/User.js';
import CompanyIdentity from '../../Classes/CompanyIdentity.js';
import MessageCenter from '../MessageCenter.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

const nestedObjectsFixer = ['customer', 'carrier', 'vehicle', 'customer_currency', 'carrier_currency', 'company_identity'];
const OrderEditorContext = createContext();

const OrderEditorProvider = ({ children, orderId }) => {
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const [order, setOrder] = useState(undefined);
    const [options, setOptions] = useState(undefined);
    const [currencies, setCurrencies] = useState(undefined);
    const [changes, setChanges] = useState({});
    const [users, setUsers] = useState(undefined);
    const [companyIdentities, setCompanyIdentities] = useState(undefined);
    const [error, setError] = React.useState(null);

    function getCurrentValue(key) {
        return changes[key] !== undefined ? changes[key] : order[key];
    }

    const reloadOrder = React.useCallback(async () => {
        setOrder(undefined);
        const queryset = new Queryset(Order);
        queryset.one(orderId).then((e) => {
            if (e?.error !== undefined) {
                setError(e.error);
                return;
            }
            setOrder(queryset.object);
        }).catch((error) => {
            setError(error);
        }).finally(() => { });
    }, [orderId]);

    const quickSaveOrder = React.useCallback(async (data) => {
        if (data === undefined) {
            data = changes;
        }
        try {
            setSaving(true);
            const newOrder = await order.save(data);
            if (order.__status === 400) {
                console.log(Object.values(order?.__messages?.error || {}).join("\n"))
                throw new Error("Neočekávaná HTTP odpověď " + order.__status);
            }
            setOrder(new Order(newOrder));
            setChanges({});
        } catch (error) {
            console.error(error);
            MessageCenter.addMessage({
                title: "Nepodařilo se uložit změny",
                text: "Zkuste to prosím znovu později, nebo kontaktujte podporu",
            });
        }
        finally {
            setSaving(false);
        }
    }, [order]);

    const copyOrder = React.useCallback(async () => {
        try {
            const copy = await order.copy()
            const numOfChanges = changes ? Object.keys(changes).length : 0;
            if (copy) {
                MessageCenter.addMessage({
                    title: "Objednávka byla úspěšně zkopírována",
                    text: `Byla vytvořena nová kopie objednávky ${order.code} (#${order.id}) - ${copy.code} (#${copy.id}). ${(numOfChanges ? "Přeneseny byly i neuložené změny. Pro jejich zachování je uložte." : "")}` 
                });
                history.push("/order/" + copy.id)
            }
            return true;
        }
        catch (error) {
            MessageCenter.addMessage({title: "Nepodařilo se zkopírovat objednávku: ", "message": error.message});
        }
    }, [history, order]);

    const saveOrder = React.useCallback(async () => {
        setSaving(true);
        for (const key in nestedObjectsFixer) {
            if (changes[nestedObjectsFixer[key]] !== undefined) {
                if (changes[nestedObjectsFixer[key]])
                    changes[nestedObjectsFixer[key]] = changes[nestedObjectsFixer[key]].id;
                else {
                    changes[nestedObjectsFixer[key]] = null;
                }
            }
        }
        try {
            const newOrder = await order.save(changes);
            if (newOrder.__status === 400) {
                console.log(Object.values(order?.__messages?.error || {}).join("\n"))
                throw new Error("Neočekávaná HTTP odpověď " + newOrder.__status);
            }
            setOrder(new Order(newOrder));
            setChanges({});
        } catch (error) {
            MessageCenter.addMessage("Nepodařilo se uložit změny", "Zkuste to prosím znovu později, nebo kontaktujte podporu", error.message);
        } finally {
            setSaving(false);
        }
    }, [order, changes]);


    React.useEffect(() => {
        const queryset = new Queryset(Order);
        queryset.one(orderId).then((e) => {
            if (e?.error !== undefined) {
                setError(e.error);
                return;
            }
            setOrder(queryset.object);
        }).catch((error) => {
            setError(error);
        }).finally(() => { });
    }, [orderId]);



    React.useEffect(() => {
        async function fetchOptions() {
            try {
                const options = await getOptions(Order);
                setOptions(options);
            } catch (error) {
                setError(error);
            }
        }
        async function fetchCurrencies() {
            try {
                const queryset = new Currencies();
                await queryset.all();
                setCurrencies(queryset.objects);
            } catch (error) {
                console.log(error);
                setError(error);
            }
        }
        async function fetchUsers() {
            try {
                const queryset = new Queryset(User);
                await queryset.all();
                setUsers(queryset.objects);
            } catch (error) {
                setError(error);
            }
        }
        async function fetchCompanyIdentities() {
            try {
                const queryset = new Queryset(CompanyIdentity);
                await queryset.all();
                setCompanyIdentities(queryset.objects);
            } catch (error) {
                setError(error);
            }
        }
        fetchCompanyIdentities();
        fetchUsers();
        fetchOptions();
        fetchCurrencies();
    }, [])


    if (error) {
        return <Alert variant="danger">
            <h1>Při načítání editoru nastala chyba. Zkuste to znovu, nebo kontaktujte podporu</h1>
            {error}
        </Alert>;
    }
    // Loading handler
    const loadingInfo = [
        [order, 'Objednávka'],
        [options, 'Konfigurace'],
        [currencies, 'Měny'],
        [users, 'Informace o uživatelích'],
        [companyIdentities, 'Identifikace společnosti']
    ].filter(([data, _]) => !data);

    if (loadingInfo.length > 0) {
        const label = loadingInfo.length === 1 ? "Načítám poslední informaci - " + loadingInfo[0][1] : "Načítám: " + loadingInfo.map(([_, label]) => label).join(", ");
        return <div className='bg-light w-100 d-flex justify-content-center align-items-center' style={{ height: 'calc(100vh - 60px)' }}>
            <div className='d-flex justify-content-center align-items-center flex-column'>
                <h3>Připravujeme editor objednávky</h3>
                <p style={{ fontStyle: 'italic' }}>{label}</p>
                <Spinner animation="border" role="status" size="lg" className="mb-1" />
            </div>
        </div>;
    }

    // Computed values
    const readOnly = !!order?.invoice;

    return (
        <OrderEditorContext.Provider value={{ order, options, changes, setChanges, currencies, readOnly, users, companyIdentities, getCurrentValue, saveOrder, quickSaveOrder, reloadOrder, copyOrder, saving }}>
            {children}
        </OrderEditorContext.Provider>
    );
};

export { OrderEditorContext, OrderEditorProvider };

import { useEffect, useState } from "react";
import { Accordion, Card, Col, OverlayTrigger, Row, Table } from "react-bootstrap";
import { OverlayComponent } from "./OverlayComponent";

const REQUIERED_PARAMS = ["source", "changes", "options"];

export default function DiffTable(props) {
	// This is useless here, could be rewritten as 
	// const changes = props.changes;
	const { changes, converters } = props;
	const source = props.source;
	const fields = props?.options?.actions?.POST;


	const missing_params = REQUIERED_PARAMS.filter((param) => props[param] === undefined);
	if (missing_params.length > 0) {
		return <div className="alert alert-danger">Nelze zobrazit rozdíly, chybí vyžadované parametry: {missing_params.join(", ")}</div>
	}

	if (changes === null || Object.keys(changes).length === 0) {
		return <div className="alert alert-success">Nebyly nalezeny žádné změny</div>
	}

	if (typeof source !== "object" || typeof changes !== "object") {
		return <div className="alert alert-danger">Nelze zobrazit rozdíly, parametry musí být objekty</div>
	}


	// Render value from an object based on a key
	// Checks for converter and existence
	function renderValue(src, key) {
		if (src[key] === undefined) {
			return "-"
		}
		if (src[key] === null) {
			return <em>Prázdné</em>
		}
		if (converters[key]) {
			return converters[key](src[key])
		}
		return src[key].toString()
	}
	// Here it's obvious that source & co are defined
		// TODO The size of the div has to be done with Row and Col
		// Based on the device size (md, lg, xl) ...
	return (
		<div>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Klíč</th>
						<th>Nová hodnota</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(changes).map((key) => <tr key={key}>
						<th>
							{fields[key]?.label || key}
						</th>
						<td className="text-danger">
							<OverlayComponent
								placement={"bottom"}
								trigger="hover"
								text={<div className="text-success">{renderValue(changes, key)}</div>}
								title={"Původní hodnota"}
							>
								{renderValue(source, key)}
							</OverlayComponent>
						</td>
					</tr>)}
				</tbody>
			</Table>
		</div>
	);
}
import React from 'react'
import { Alert, Container, Table, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import { Person, Sliders } from 'react-bootstrap-icons';
import { withRouter } from "react-router-dom";
import Account from '../Classes/Account';
import settings from '../Settings';
import { usePersistStorage } from '../Context';

export function ToggleNewOrderView() {
  const [config, setConfig] = usePersistStorage("config");

  return <Form>
    <Form.Group controlId="manual_order_saving">
      <Form.Check type="switch" id="manual_order_saving" label="Nový editor objednávek (s ručním ukládáním)" checked={!!config?.order_manual_saving} onChange={e => {
        setConfig({...config, "order_manual_saving": e.target.checked})
      }} />
    </Form.Group>
  </Form>
}

class PreferencesView extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
          "user" : Account.currentUser(),
          "current" : "",
          "password" : "",
          "password2" : "",
          "passwordChangeRunning" : false
      }
  }

  async changePassword(e) {
    this.setState({"passwordChangeRunning" : true})
    e.preventDefault()
    const res = await this.state.user.changePassword(this.state.current, this.state.password)
    const data = {
      "passwordChangeRunning" : false,
      "pwchange_res": res
    }
    if (res.status === 200) {
      data.current = ""
      data.password = ""
      data.password2 = ""
    }
    this.setState(data)
  }

  render() {
    const passwordOk = this.state.password === this.state.password2 && this.state.password.length >= 8 && this.state.current
    return <Container>
        <h1 className="mt-2">Nastavení profilu</h1>
        <hr></hr>
        <Row>
          <Col md="4" className="bg-light border-rounded">
            <h2 className><Person className='mr-2'/> Uživatel <span></span></h2>
              <Table>
                <tbody>
                  <tr><th>Uživatelské jméno</th><td>{this.state.user.username}</td></tr>
                  <tr><th>Křestní jméno</th><td>{this.state.user.first_name}</td></tr>
                  <tr><th>Příjmení</th><td>{this.state.user.last_name}</td></tr>
                  <tr><th>E-mail</th><td>{this.state.user.email}</td></tr>
                  <tr><th>Telefon</th><td>{this.state.user.operatorprofile && this.state.user.operatorprofile.phone_number}</td></tr>
                  {this.state.user.level > 0 && <tr><td colSpan="2"><strong>Vyšší práva!</strong><br /> Máte přístup k více objednávkám a je přístupné nastavení systému</td></tr>}
                </tbody>
              </Table>
              <h2 className><Sliders /> Nastavení</h2>
              <ToggleNewOrderView />
          </Col>
          <Col>
            <h2 className="text-center">Změna hesla</h2>
            <Form style={{maxWidth: "400px", margin: "0 auto"}} onSubmit={e => this.changePassword(e)}>
              {this.state.pwchange_res && <Alert variant={this.state.pwchange_res.status === 200 ? "success" : "danger"} onClose={() => this.setState({pwchange_res: undefined})} dismissible>
                  {this.state.pwchange_res.res.result}
              </Alert>}
              <Form.Group controlId="current">
                <Form.Label>Současné heslo</Form.Label>
                <Form.Control type="password" placeholder="Současné heslo" value={this.state.current} onChange={e => this.setState({current: e.target.value})}/>
              </Form.Group>
              <Form.Group controlId="newPassword">
                <Form.Label>Nové heslo</Form.Label>
                <Form.Control type="password" placeholder="Nové heslo"  value={this.state.password} onChange={e => this.setState({password: e.target.value})}/>
              </Form.Group>
              <Form.Group controlId="newPassword2">
                <Form.Label>Nové heslo (kontrola)</Form.Label>
                <Form.Control type="password" placeholder="Nové heslo znovu"  value={this.state.password2} onChange={e => this.setState({password2: e.target.value})}/>
              </Form.Group>
              {!passwordOk && this.state.password && <Alert variant="danger" className="text-center">
                Hesla si neodpovídají, nebo je kratší než 8 znaků.
              </Alert>}
              <Button block="block" disabled={!passwordOk} variant="primary" type="submit" className="d-flex justify-content-between align-items-center">
                <span></span>
                Změnit heslo
                {this.state.passwordChangeRunning ? <Spinner animation="grow" size="sm" /> : <span></span>}
              </Button>
            </Form>
          </Col>
        </Row>
        <div className="mt-5 text-secondary text-right">Verze FE: {settings.VERSION}</div>
    </Container>
  }
}
export default withRouter(PreferencesView);
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Card, Row, Col, InputGroup } from 'react-bootstrap';
import { Lock, X } from 'react-bootstrap-icons';
import { Calendar, GeoAlt, Person, Telephone, CodeSlash } from 'react-bootstrap-icons'; // Import icons
import Location from '../../Classes/Location';
import RichTextEditor from '../RichTextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { DraftJSexportOptions, DraftJSimportOptions } from '../../Helpers/draftHTMLhelpers';
import PlaceSelector from '../PlaceSelector'; // Import PlaceSelector
import { HumanReadableMiliseconds } from '../../helpers';
import MessageCenter from '../MessageCenter';


const TimezonedDatetime = (props) => {
    const [tz, setTZ] = useState(0);
    const [date, setDate] = useState();
    const [error, setError] = useState();

    // We have to display the value in UTC but add the offset to trick the users. 
    // Back we have to subtract it again
    // TODO: it doesn't work with e.target.value ... We might need to use a different approach

    useEffect(() => {
        if (!props.value) {
            setTZ(new Date().getTimezoneOffset());
            setDate(null);
        } else {
            try {
                const date = new Date(props.value);
                const offset = date.getTimezoneOffset();
                setTZ(offset);
                setDate(new Date(date.getTime() - offset * 60000));
            } catch (e) {
                setError(e.message);
            }
        }
    }, [props.value])

    if (error) {
        return "Error: " + error;
    }

    function onChange(e) {
        if (!e.target.validity.valid) return;
        const date = new Date(e.target.value);
        if (!date) {
            MessageCenter.addMessage({
                'title': 'Nepodařilo se načíst datum',
                'text': 'Zkuste to prosím znovu a případně kontaktujte podporu',
            })
        }
        props.onChange && props.onChange(date.toISOString());
    }

    function formatDate(date) {
        return date.toISOString().slice(0, 16);
    }

    return (
        <Form.Control
            type="datetime-local"
            defaultValue={date ? formatDate(date) : ""}
            onChange={onChange}
            isInvalid={!!props.error}
        />
    )
}

const LocationModalEditor = (props) => {
    const [changes, setChanges] = useState({});
    const [errors, setErrors] = useState({});
    const [tz, setTZ] = useState(0);

    useEffect(() => {
        //Offset to UTC
        const tzOffset = new Date().getTimezoneOffset() * 60000;
        setChanges(props.location || {});
        setErrors({});
        setTZ(tzOffset);
    }, [props.location]);

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    };

    const handleSave = async () => {
        try {
            const location = new Location(changes);
            await location.save();
            if (location.__status !== 200 && location.__status !== 201) {
                console.error(location.__messages);
                setErrors(location.__messages || {});
                return;
            }
            props.onSave && props.onSave(changes);
        }
        catch (e) {
            console.error(e);
            setErrors(e);
        }

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setChanges({ ...changes, [name]: value });
    };

    const handleDateTimeChange = (value, field) => {
        setChanges(prevState => {
            const newState = { ...prevState, [field]: value };
            return newState;
        });
    };

    const clearEndTime = () => {
        setChanges(prevState => ({ ...prevState, end_time: null }));
    };

    const handlePlaceSelected = (place) => {
        setChanges({ ...changes, place });
    };

    const location_start = changes?.time && new Date(changes.time);
    const location_end = changes?.end_time && new Date(changes.end_time);

    return (
        <Modal show={changes?.id !== undefined} onHide={handleCancel} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {changes?.id ? "Editace zastávky" : "Nová zastávka"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col lg={6} className="d-flex flex-column">
                            <div className={changes.place ? "py-1 px-2 border rounded" : ""}>
                                <PlaceSelector place={changes.place} placeSelected={handlePlaceSelected} />
                            </div>
                            <Card className="mt-2 h-100">
                                <Card.Header className="d-flex justify-content-between">
                                    <span><Calendar /> Datum a čas</span>
                                    <Form.Check
                                        label="Fixní časové okno"
                                        id='fixed_window_input'
                                        checked={changes.fixed_window || false}
                                        onChange={e => handleChange({ target: { name: 'fixed_window', value: e.target.checked } })}
                                    />
                                </Card.Header>
                                <Card.Body>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text style={{ minWidth: "85px" }} className="justify-content-center">Začátek</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <TimezonedDatetime
                                            value={changes.time && new Date(changes.time)}
                                            onChange={(e) => handleDateTimeChange(e, 'time')}
                                            />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.time}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    <InputGroup style={{ marginTop: "-1px" }}>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text style={{ minWidth: "85px" }} className="justify-content-center">Konec</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <TimezonedDatetime
                                            value={changes.end_time && new Date(changes.end_time)}
                                            onChange={(e) => handleDateTimeChange(e, 'end_time')}
                                            />
                                        <InputGroup.Append>
                                            <Button variant="outline-danger" onClick={clearEndTime}>
                                                <X />
                                            </Button>
                                        </InputGroup.Append>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.end_time}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    {(location_start && location_end && location_start.getTime() !== location_end.getTime()) && <>{location_start > location_end ?
                                        <Form.Text className="text-danger">Čas ukončení je před časem začátku</Form.Text> :
                                        <Form.Text className="text-success">Velikost okna: {HumanReadableMiliseconds(location_end.getTime() - location_start.getTime())}</Form.Text>}</>}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Typ</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    name="type"
                                    value={changes.type || ''}
                                    onChange={handleChange}
                                    isInvalid={!!errors.type}
                                >
                                    <option value="LOAD">Nakládka</option>
                                    <option value="UNLOAD">Vykládka</option>
                                    <option value="TOLL">Mýto</option>
                                    <option value="OTHER">Jiné</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.type}
                                </Form.Control.Feedback>
                            </InputGroup>
                            <Card className="mt-2">
                                <Card.Header><Person /> Kontakt</Card.Header> {/* Add icon */}
                                <Card.Body>
                                    {["name", "phone", "code"].map((field, index) => (
                                        <InputGroup key={index} style={{ marginTop: "-1px" }}>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text style={{ minWidth: "100px" }}>
                                                    {field === "name" ? <Person /> : field === "phone" ? <Telephone /> : <Lock />} {/* Add icons */}
                                                    <span className="ml-1">{field === "name" ? "Jméno" : field === "phone" ? "Telefon" : "CODE"}</span>
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="text"
                                                name={field}
                                                value={changes[field] || ''}
                                                onChange={handleChange}
                                                isInvalid={!!errors[field]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors[field]}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mt-2">
                            <Card>
                                <Card.Header>Poznámky</Card.Header>
                                <Card.Body>
                                    <Form.Group>
                                        <RichTextEditor
                                            editorState={EditorState.createWithContent(stateFromHTML(changes.notes || '', DraftJSimportOptions))}
                                            features={{
                                                small_buttons: true,
                                                load_changes: false,
                                                formats: false,
                                            }}
                                            setEditorState={(e) => {
                                                const content = stateToHTML(e.getCurrentContent(), DraftJSexportOptions);
                                                if (convertToRaw(stateFromHTML(changes.notes || '', DraftJSimportOptions)) !== convertToRaw(e.getCurrentContent())) {
                                                    handleChange({ target: { name: 'notes', value: content } });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.notes}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between align-items-center'>
                {(changes?.id === undefined || changes?.id === 0) ? (
                    <div>
                        <div className="font-weight-bold mr-2">Umístění: </div>
                        <Form.Check
                            inline
                            type="radio"
                            name="position"
                            value="100000"
                            label="Na začátek"
                            checked={String(changes.position) === "100000"}
                            onChange={e => handleChange({ target: { name: 'position', value: e.target.value } })}
                        />
                        <Form.Check
                            inline
                            type="radio"
                            name="position"
                            value="0"
                            label="Na konec"
                            checked={String(changes.position) === "0"}
                            onChange={e => handleChange({ target: { name: 'position', value: e.target.value } })}
                        />
                    </div>
                ) : <div></div>}
                <div>
                    <Button variant="secondary" onClick={handleCancel}>
                        Zavřít
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Uložit změny
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default LocationModalEditor;

// Description: Component for displaying and changing carrier contact.
import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { PersonBadge, PersonFillAdd } from 'react-bootstrap-icons'
import Contact from '../Classes/Contact'
import SearchModal from './SearchModal'

function CarrierContact(props) {
    const { carrier, orderContact } = props
    if (!carrier) return null;

    const contact = orderContact || (carrier?.contact_set.filter(c => c.is_informacni) || []).shift();

    const popover = (
        <Popover id="popover-basic" style={{ zIndex: 500 }}>
            <Popover.Title as="h3" className='text-center'>{contact ? contact.name : "Bez kontaktu"}</Popover.Title>
            <Popover.Content>
                {contact && <><div>{contact.email ? <a href={`mailto:${contact.email}`}>{contact.email}</a> :
                    <em>E-mail chybí</em>
                }</div>
                    <div>{contact.phone ? <a href={`mailto:${contact.phone}`}>{contact.phone}</a> :
                        <em>Telefon chybí</em>
                    }</div></>}
                <div className='text-center mt-2'>
                    <SearchModal
                        size="sm"
                        label={contact ? "Změnit" : "Přiřadit kontakt"}
                        variant="outline-secondary"
                        klass={Contact}
                        filter={{ carrier: carrier.id }}
                        fields={['name', 'email', 'phone']}
                        createNew={["name", "email", "phone", "is_informacni"]}
                        createNewFixedValues={{ carrier: carrier.id }}
                        closeCallback={contact => props?.onCarrierContactSelected(contact)}
                    />
                </div>
            </Popover.Content>
        </Popover>
    );

    return <OverlayTrigger key="contact" trigger="click" placement="right" overlay={popover}>
        <Button variant={contact ? 'outline-primary' : 'outline-success'} size='sm' label="Změnit kontakt">
            {contact ? <PersonBadge /> : <PersonFillAdd />}
        </Button>
    </OverlayTrigger>
}

export default CarrierContact
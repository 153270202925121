import { useState } from "react"
import { Dropdown, Modal, Button, Form, Row, Col } from "react-bootstrap"
import { Alarm, GeoAlt, Pencil, X } from "react-bootstrap-icons"
import { dateAddMS, dateDayOfWeek, dateToInputValue, dateToLocalNoSeconds, dateToLocalNoSecondsNoSeconds, HumanReadableMiliseconds, secondsBetween } from "../helpers";


export default function TimeMover(props) {
    const [show, setShow] = useState(false);
    const [time, setTime] = useState(new Date(props.firstLocationTime).toLocaleString("sv"));
    const [timeOverride, setTimeOverride] = useState(props.locations.map(_ => null));
    const [editTime, setEditTime] = useState(props.locations.map(_ => false));
    const differenceInMS = secondsBetween(time, props.firstLocationTime);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
        setTime(new Date(props.firstLocationTime).toLocaleString("sv"));
    }
    const trigger = () => {
        handleClose()
        props.onSelected && props.onSelected(differenceInMS, timeOverride);
    }

    function enableEditTime(index) {
        editTime[index] = true
        // If edit enabled, provide a meaningful default value
        if (timeOverride[index] === null) {
            timeOverride[index] = new Date(props.locations[index].time).toLocaleString("sv")
            setTimeOverride([...timeOverride])
        }
        setEditTime([...editTime])
    }

    function cancelEditTime(index) {
        editTime[index] = false
        timeOverride[index] = null
        setTimeOverride([...timeOverride])
        setEditTime([...editTime])
    }

    function overrideTime(index, value) {
        timeOverride[index] = value
        setTimeOverride([...timeOverride])
    }

    return <>
        <Dropdown.Item target="_blank" onClick={handleShow}><Alarm /> Posun v čase</Dropdown.Item>

        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Posun v čase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="5">
                        <Form.Group>
                            <Form.Label><strong>Nový čas</strong></Form.Label>
                            <Form.Control type="datetime-local" value={time} onChange={e => setTime(e.target.value)} />
                        </Form.Group>
                        {time === props.firstLocationTime || <div className={time > props.firstLocationTime ? "text-right text-success" : "text-right text-danger"}>
                            Změna: {HumanReadableMiliseconds(differenceInMS)}
                        </div>}
                        <div className="text-justify">
                            <em>Při zadání nového času dojde k posunu první zastávky na daný čas. Všechny ostatní zastávky se posunou relativně k nové zastávce</em>
                        </div>
                    </Col>
                    <Col md="7">
                        <table style={{ width: "100%" }}>
                            <tbody>
                                {(props.locations || []).map((location, index) => <tr key={location.id}>
                                    <td>
                                        {location.place ? <div className="d-flex justify-content-between">
                                            <strong>{location.place.name}</strong>
                                            <strong><GeoAlt /> {location.place.country} {location.place.postal_code}</strong>
                                        </div> : <div><em>Neurčené místo</em></div>}
                                        <div className="d-flex justify-content-between">
                                            {
                                                !differenceInMS ?
                                                    <em className="text-success">{dateDayOfWeek(location.time)} {dateToLocalNoSeconds(location.time)}</em> :
                                                    <>
                                                        <del className="text-danger">{dateDayOfWeek(location.time)} {dateToLocalNoSeconds(location.time)}</del>{" "}
                                                        <div className="d-flex align-items-center text-success">
                                                            <span className="mr-2">{dateDayOfWeek(timeOverride[index] || dateAddMS(location.time, differenceInMS))}</span>
                                                            {
                                                                editTime[index] ?
                                                                    <div className="d-flex align-items-center">
                                                                        <Form.Control type="datetime-local" value={timeOverride[index] || dateAddMS(location.time, differenceInMS)} onChange={e => overrideTime(index, e.target.value)} />
                                                                        <Button className="ml-1" variant="outline-danger" title="Smazat přepsání času zastávky" size="sm" onClick={() => cancelEditTime(index)}><X /></Button>
                                                                    </div>:
                                                                    <div>
                                                                        {index > 0 && <Button variant="outline-success" className="mr-1" size="sm" onClick={() => enableEditTime(index)}><Pencil /></Button>}
                                                                        <span className="text-success">{dateToLocalNoSeconds(dateAddMS(location.time, differenceInMS))}</span>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Zavřít
                </Button>
                <Button variant="success" onClick={trigger} disabled={!time || !differenceInMS}>
                    <Alarm /> Posun
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}